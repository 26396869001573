import React, { useEffect, useState } from 'react';
import axios from 'axios';

import './EnquiryForm.css';

const NewForm = (props) => {
  const [msgBody, setMsgBody] = useState('');
  const [subject, setSubject] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const [fname, setFname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');

  const recipient = props.key1;
  useEffect(() => {
    const clearMessages = () => {
      setErrorMsg('');
      setSuccessMsg('');
    };

    const timeout = setTimeout(clearMessages, 5000); // Clear messages after 5 seconds

    return () => clearTimeout(timeout); // Clean up the timeout on component unmount
  }, []);

  const save = (event) => {
    event.preventDefault();

    if (!recipient || !msgBody || !fname || !mobile) {
      setErrorMsg('Please fill in all required fields.');
      return;
    }

    const subject = fname;
    const message = `Name: ${fname}\nEmail: ${email}\nMobile: ${mobile}\n\n${msgBody}`;

    axios
      .post('https://backend.aptkard.in/aptkard/sendMail', { recipient, msgBody: message, subject })
      .then((response) => {
        setSuccessMsg('Message sent successfully!');
        setMsgBody('');
        setSubject('');
        setAttachment(null);
        setFname('');
        setEmail('');
        setMobile('');
      })
      .catch((error) => {
        setErrorMsg('Failed to send message.');
      });
  };

  return (
    <>
      <center>
        <h4 style={{ color: 'white' }}>Enquiry Form</h4>
      </center>
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
      {successMsg && <div className="alert alert-success">{successMsg}</div>}
      <form style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="d-flex flex-column align-items-center EquiryForm">
          <input
            type="text"
            id="fname"
            name="fname"
            placeholder="Name"
            className="form-control mb-3"
            value={fname}
            onChange={(e) => setFname(e.target.value)}
          />
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Email"
            className="form-control mb-3"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            id="mobile"
            name="mobile"
            placeholder="Mobile"
            className="form-control mb-3"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          <textarea
            id="msgBody"
            name="msgBody"
            placeholder="Type your Message"
            className="form-control mb-3"
            value={msgBody}
            onChange={(e) => setMsgBody(e.target.value)}
          />
          <button
            className="btn btn-primary"
            type="button"
            style={{
              width: '50%',
              marginTop: '1px',
            }}
            onClick={save}
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default NewForm;

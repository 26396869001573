import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Template1 from '../Templates/Template1';
import Template2 from '../Templates/Template2';
import Template3 from '../Templates/Template3';
import Template4 from '../Templates/Template4';
import Template5 from '../Templates/Template5';
import axios from "axios";

const EmployeePage = () => {
  const [id, setId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [data, setData] = useState([]);

  let parsedId = null;
  let parsedUsername = null;

  useEffect(() => {
    const path = window.location.pathname.split('/');
    parsedId = parseInt(path[1]);
    parsedUsername = path[2];

    if (!isNaN(parsedId) && parsedUsername) {
      setId(parsedId);
      setUserName(parsedUsername);
    }

    axios.get(`https://backend.aptkard.in/aptkard/${parsedId}/${parsedUsername}`)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []); // Include parsedId and parsedUsername in the dependency array

  const templateRoutes = data.map((element, index) => {
    switch (element.templateStyle) {
      case "Template1":
        return <Route key={index} path={`/*`} element={<Template1 id={id} userName={userName} />} />;
      case "Template2":
        return <Route key={index} path={`/*`} element={<Template2 id={id} userName={userName} />} />;
      case "Template3":
        return <Route key={index} path={`/*`} element={<Template3 id={id} userName={userName} />} />;
      case "Template4":
        return <Route key={index} path={`/*`} element={<Template4 id={id} userName={userName} />} />;
        case "Template5":
          return <Route key={index} path={`/*`} element={<Template5 id={id} userName={userName} />} />;
      default:
        return null;
    }
  });

  return (
    <Routes>
      {id !== null && userName !== "" && templateRoutes}
    </Routes>
  );
}

export default EmployeePage;
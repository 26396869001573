import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import styles from './Home.module.css';
const Home = () => {
  const navigate = useNavigate();

  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [allUsersCount, setAllUsersCount] = useState(0);
  const [activeUsersCount, setActiveUsersCount] = useState(0);

  const handleLogout = () => {
    Swal.fire({
      title: 'Logout Confirmation',
      text: 'Are you sure you want to log out?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoggedOut(true);
      }
    });
  };

  useEffect(() => {
    if (isLoggedOut) {
      navigate('/admin', { replace: true });
    }
  }, [isLoggedOut, navigate]);

  useEffect(() => {
    const fetchCount = async () => {
      try {
        const url = "https://backend.aptkard.in/aptkard/userCount";
        const response = await fetch(url);
        const data = await response.json();
        setAllUsersCount(data); // assuming the response is the count of active users
      } catch (error) {
        console.error(error);
      }
    };
    fetchCount();
  }, []);

  useEffect(() => {
    const func_one = async () => {
      try {
        const url = "https://backend.aptkard.in/aptkard/activeCount";
        const response = await fetch(url);
        const data = await response.json();
        setActiveUsersCount(data);

      }
      catch (error) {
        console.error(error);
      }
    }
    func_one();
  });

  return (
    <>


      <div className={styles.div} style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <div className="row" style={{ width: '100%' }}>
          <div style={{ display: "flex", color: "black", fontFamily: "Nunito", justifyContent: "center", alignItems: "center" }} className="col-md-6">
            <div className="card" style={{ width: "250px", height: "150px", color: "black" }}>
              <div className="card-body">
                <h5 className="card-title">Active Subscribers</h5>     <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'green' }}></div>
                <span className="h1 me-4">{allUsersCount}</span>
                <span className="fs-5">subscribers</span>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", fontFamily: "Nunito", justifyContent: "center", alignItems: "center" }} className="col-md-6">
            <div className="card" style={{ width: "250px", height: "150px", color: "black" }}>
              <div className="card-body">
                <h5 className="card-title">Subscribers</h5>
                <div className="row">
                  <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'green', margin: '10px' }}></div>
                  <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: 'red', margin: '10px' }}></div>

                </div>

                <span className="h1 me-4">{allUsersCount}</span>
                <span className="fs-5">Subscribers</span>

              </div>
            </div>
          </div>
        </div>
      </div>







    </>
  );
};

export default Home;
import axios from "axios";
import { useUserContext } from "../Context/UserContext";  // Import the context hook
import styles from './ChooseTemplateScreen.module.css'
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

const ChooseTemplateScreen = () => {
  const { userData } = useUserContext();
  const [data, setData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingMore, setLoadingMore] = useState(false);

  const fetchData = async () => {
    setLoadingMore(true);
    try {
      const response = await fetch(`https://backend.aptkard.in/aptkard/${userData.userId}/${userData.username}`);
      if (response.ok) {
        const data = await response.json();
        setData(data); // Update state with fetched data
        setSelectedOption(data.length > 0 ? data[0].templateStyle : null); // Set initial selected option
      } else {
        console.error('Failed to fetch data');
        setLoadingMore(false);
      }
      setLoadingMore(false);
    } catch (error) {
      setLoadingMore(false);
      console.error(error);
    }
  };

  const handleUpdateClick = async () => {
    setLoading(true);
    if (!selectedOption) {
      console.log('Please select a template option');
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.put(`https://backend.aptkard.in/aptkard/updateTemplateStyle/${userData.userId}`, null, {
        params: {
          templateStyle: selectedOption,
        },
      });
      Swal.fire({
        title: 'Data Updated!',
        text: 'Successfully updated data!',
        icon: 'success',
      });
      console.log(selectedOption);
      // Handle successful response here
      console.log(response);
      fetchData();
      setLoading(false);
    } catch (error) {
      // Handle error here
      console.error(error);
      setLoading(false);
      Swal.fire({
        title: 'Error while Updateing the data!',
        text: 'Successfully updated data!',
        icon: 'error',
      });
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  

  return (
    <>
        <div className={`d-flex justify-content-center align-items-center ${styles.container}`}>
          <div className={`row ${styles.card}`}>
          {
            isLoadingMore ? (<div className="d-flex justify-content-center text-center align-content-cenetr">
                <p>Loading.........</p>
            </div>): 
            (<div className={`${styles.col} col-3 bg-gradient text-center` }>     
            { 
              data.map((user) => {
                if (user.templateStyle === 'Template1') {
                  return(
                    <> 
                        <p key={user.id} className="text-center">Your Current Template</p>
                        <p className="text-center" >Template 1:</p> 
                        <p className="text-center fw-bold h5">BLACK PREMIUM</p>  
                        <img 
                          src="../assests/Template1.jpeg"
                          className="img-fluid"
                          style={{maxWidth : '180px'}}
                          alt="tempalte1"
                        />      
                    </>
                  )
                } else if (user.templateStyle === 'Template2') {
                  return(
                    <> 
                      <p key={user.id} className="text-center">Your Current Template</p>
                      <p className="text-center" >Template 2:</p> 
                      <p className="text-center fw-bold h5">BUBBLE ANIMATION</p>  
                        <img 
                          src="../assests/Template2.jpeg"
                          className="img-fluid"
                          style={{maxWidth : '80px'}}
                          alt="tempalte2"
                        />
                    </>
                  )
                } else if (user.templateStyle === 'Template3') {
                  return(
                    <> 
                      <p key={user.id} className="text-center">Your Current Template</p>
                      <p className="text-center" >Template 3:</p> 
                      <p className="text-center fw-bold h5">DARK/LIGHT MODE</p>  
                      <img 
                          src="../assests/Template3.jpeg"
                          className="img-fluid"
                          style={{maxWidth : '190px'}}
                          alt="tempalte3"
                        />
                    </>
                  )
                }
              }
            )
            }
          </div>)

          }
          <div className="col-9">
            <h6 className="text-center" >choose an option to update your template</h6>
            <div className="row ms-4">
              <div className="col-3 border ms-4">
              <p className="text-center">Template 1:</p> 
              <p className="text-center fw-bold h6">BLACK PREMIUM</p>  
              <img 
                      src="../assests/Template1.jpeg"
                      className="img-fluid"
                      alt="tempalte"
              />   
              <div  className="text-center d-flex justify-content-center m-1" >
                <input type="radio"
                  name="option"
                  value={data.templateStyle || ''}
                  checked={selectedOption === 'Template1'}
                  onChange={() => setSelectedOption('Template1')}
                  style={{ width : '30px' , height : '30px'}}
                />
              </div>    
              </div>
              <div className="col-3 border ms-4">
              <p className="text-center">Template 2:</p> 
              <p className="text-center fw-bold h6">BUBBLE ANIMATION</p>  
                <img 
                      src="../assests/Template2.jpeg"
                      className="img-fluid"
                      alt="tempalte2"
                />  
              <div  className="text-center d-flex justify-content-center m-1" >
                <input
                name="option"
               value={data.templateStyle || ''}
                checked={selectedOption === 'Template2'}
                onChange={() => setSelectedOption('Template2')}
                style={{ width : '30px' , height : '30px'}}
                type="radio" />
               
              </div>     
              </div>
              <div className="col-3 ms-4 border">
              <p className="text-center">Template 3:</p> 
              <p className="text-center fw-bold h6">DARK/LIGHT MODE</p>  
                <img 
                      src="../assests/Template3.jpeg"
                      className="img-fluid"
                      alt="tempalte3"
                />
              <div  className="text-center d-flex justify-content-center m-1" >              
                <input 
                name="option"
                value={data.templateStyle || ''}
                checked={selectedOption === 'Template3'}
                onChange={() => setSelectedOption('Template3')}
                style={{ width : '30px' , height : '30px'}}
                type="radio" />
              </div>
              </div>
            </div>
            <div className="container">
              <div className="d-flex justify-content-between m-1 position-relative">
                <button className="btn btn-info btn-sm btn-outline-dark position-absolute top-0 end-0" onClick={handleUpdateClick}>{  isLoading ? "Loading..."  : "Update" }</button>
              </div>
            </div>
          </div>     
          </div>
        </div>
    </>
  );
};

export default ChooseTemplateScreen;
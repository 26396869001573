import React from "react";
import {
  FaFacebook,
  FaPhone,
  FaTwitter,
  FaMapMarkerAlt,
  FaYahoo,
  FaLink,
  FaInstagram,
  FaSnapchatGhost,
  FaPinterest,
  FaLinkedin,
  FaYoutube,
  FaEnvelope,
  FaWhatsapp,
  FaEye,
  FaComment,
  FaGoogleDrive,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import { BsFillArrowDownSquareFill } from "react-icons/bs";
import styles from "./Template1.module.css";
import ExchangeContact from "../Components/ExchangeContact";
import User from "../Components/ShareButton";
import NewForm from "../Components/EnquiryForm";

const Template1 = (props) => {
  console.log(props);
  const [count, setCount] = useState(null);
  const [users, setUsers] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [suggestedName, setSuggestedName] = useState("");
  const [showExchangeContactForm, setShowExchangeContactForm] = useState(false);
  const [promptDisplayed, setPromptDisplayed] = useState(false);

  const handleExchangeContactClick = () => {
    setShowExchangeContactForm(true);
  };
  const [fullName, setFullName] = useState("");
  const [fileName, setFileName] = useState(""); // Define fileName state
  
  useEffect(() => {
    const fetchData = fetch(
      `https://backend.aptkard.in/aptkard/${props.id}/${props.userName}`
    );
  
    fetchData
      .then((dataResponse) => {
        dataResponse.json().then((data) => {
          setUsers(data);
          console.log(data);
          setCount(data[0].views);
        });
      })
      .catch(() => setUsers("server error/server down"));
  }, [props.userName, props.id]);

  useEffect(() => {
    // Increment views count
    fetch(
      `https://backend.aptkard.in/aptkard/incrementViewCount?username=${props.userName}`,
      {
        method: "PUT",
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to increment views count");
        }

        // Update count state
        setCount((prevCount) => prevCount + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const formatFileSize = (fileSize) => {
    if (fileSize < 1024) {
      return `${fileSize} B`;
    } else if (fileSize < 1024 * 1024) {
      const fileSizeInKB = Math.round(fileSize / 1024);
      return `${fileSizeInKB} KB`;
    } else {
      const fileSizeInMB = Math.round(fileSize / (1024 * 1024));
      return `${fileSizeInMB} MB`;
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleGoogleMapsRedirect = (location) => {
    const googleMapsLink = `https://www.google.com/maps/place/${encodeURIComponent(
      location
    )}`;
    window.open(googleMapsLink, "_blank");
  };

  const handleSaveContact = (username) => {
    // Prompt the user to enter a file name without .vcf extension
    const suggestedFileName = `${props.userName}`;
    const fileName = window.prompt("Enter a file name :", suggestedFileName);

    if (!fileName) {
      console.log("Download canceled by user.");
      return;
    }

    const encodedFileName = encodeURIComponent(fileName);

    const fetchVCF = fetch(
      `https://backend.aptkard.in/aptkard/contacts.vcf/${username}?fileName=${encodedFileName}`
    );

    fetchVCF
      .then((vcfResponse) => {
        // Handle the VCF response
        vcfResponse.blob().then((blob) => {
          // Append .vcf to the fileName
          const downloadFileName = `${fileName}`;
          const downloadLink = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadLink;
          // Use the user-entered file name with .vcf
          a.download = `${username}.vcf`;
          a.click();
        });
      })
      .catch(() => console.log("Contact save error"));
  };
  return (
    <div className={styles.parentDiv}>
      <>
        {users.map((element) => {
          if (element.status === "active") {
            return (
              <>
                <div className={`${styles.childDivOne} container text-center`}>
                  <div className={`${styles.cardone} card `}>
                    <div className="card-body">
                      <div className={`${styles.containercount} container-sm`}>
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            backgroundColor: "black",
                            height: "24px",
                            width: "140px",
                            borderRadius: "5px",
                            border: "1px solid white",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0 5px",
                            }}
                          >
                            <FaEye
                              style={{
                                marginRight: "0.5rem",
                                fontSize: "1rem",
                                color: "white",
                              }}
                            />
                            <h6
                              style={{
                                margin: "0",
                                fontSize: "1rem",
                                color: "white",
                              }}
                            >
                              Views: {element.views}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mr-0"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "60px",
                        }}
                      >
                        <img
                          src={`data:image/jpeg;base64,${element.logo}`}
                          alt=""
                          style={{
                            borderRadius: "20px",
                            maxWidth: "100%",
                            height: "auto",
                            width: "120px",
                          }}
                        />
                      </div>
                      <div className="card mt-4">
                        <div className="card-body">
                          <div className="row align-items-center justify-content-center">
                            {element.photo && (
                              <div
                                className="col-sm-6 col-md-6 text-center"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={`data:image/jpeg;base64,${element.photo}`}
                                  alt=""
                                  style={{
                                    borderRadius: "50%",
                                    width: "150px",
                                    height: "150px",
                                  }}
                                />
                              </div>
                            )}
                            <div
                              className={`col-sm-12 col-md-6 ${
                                !element.photo ? "text-center" : ""
                              }`}
                            >
                              {element.fullName && <h3>{element.fullName}</h3>}
                              <h6>{element.designation}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-flex mt-4">
                        <div className="col-4  d-flex justify-content-center">
                          <button
                            className={`${styles.sharebutton} btn btn-secondary  btn-sm`}
                            onClick={() => handleSaveContact(element.username)}
                          >
                            Save Contact
                          </button>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                          {" "}
                          {/* Right align the content */}
                          <button
                            onClick={handleExchangeContactClick}
                            className={`${styles.sharebutton} btn btn-secondary btn-sm`}
                          >
                            <ExchangeContact
                              fullName={element.fullName}
                              email={element.email}
                            ></ExchangeContact>
                          </button>
                        </div>
                        <div className="col-4 d-flex justify-content-center">
                          {" "}
                          {/* Center align the content */}
                          <button
                            className={`${styles.sharebutton} btn btn-secondary btn-sm`}
                          >
                            <User key2={element.username} key3={element.id}>
                              Share
                            </User>
                          </button>
                        </div>
                      </div>
                      <div
                        className={`${styles.firstIconsDiv} container mt-4`}
                        style={{ wordWrap: "break-word" }}
                      >
                        {element.email && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "1rem",
                            }}
                          >
                            <FaEnvelope
                              style={{
                                paddingRight: "0.5rem",
                                fontSize: "2rem",
                              }}
                            />
                            <a
                              href={`mailto:${element.email}`}
                              className="contact-link"
                              rel="noreferrer"
                              target="_blank"
                              style={{
                                color: "whitesmoke",
                                textDecoration: "none",
                              }}
                            >
                              {element.email}
                            </a>
                          </div>
                        )}

             


                        {element.phoneNumber && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "1rem",
                            }}
                          >
                            <FaPhone
                              style={{
                                paddingRight: "0.6rem",
                                fontSize: "2rem",
                              }}
                            />
                            <a
                              href={`tel:${element.phoneNumber}`}
                              className="contact-link"
                              style={{
                                color: "whitesmoke",
                                textDecoration: "none",
                              }}
                            >
                              {element.phoneNumber}
                            </a>
                          </div>
                        )}
                        {element.location && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "1rem",
                              textAlign: "start",
                            }}
                          >
                            <FaMapMarkerAlt
                              style={{
                                paddingRight: "0.5rem",
                                fontSize: "2rem",
                              }}
                            />
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                element.location
                              )}`}
                              target="_blank"
                              rel="noreferrer"
                              className="contact-link"
                              style={{
                                color: "whitesmoke",
                                textDecoration: "none",
                                wordBreak: "break-all",
                              }}
                            >
                              {element.location}
                            </a>
                          </div>
                        )}
                        {element.websiteUrl && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "1rem",
                            }}
                          >
                            <FaLink
                              style={{
                                paddingRight: "0.2rem",
                                fontSize: "2rem",
                              }}
                            />
                            <a
                              href={element.websiteUrl}
                              rel="noreferrer"
                              target="_blank"
                              className="contact-link"
                              style={{
                                color: "whitesmoke",
                                textDecoration: "none",
                                wordBreak: "break-all",
                              }}
                            >
                              {element.websiteUrl}
                            </a>
                          </div>
                        )}
                        {element.whatsappNumber && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "1rem",
                            }}
                          >
                            <FaWhatsapp
                              style={{
                                paddingRight: "0.2rem",
                                fontSize: "2rem",
                              }}
                            />
                            <a
                              href={`whatsapp://send?phone=${element.whatsappNumber}`}
                              className="contact-link"
                              rel="noopener noreferrer"
                              target="_blank"
                              style={{
                                color: "whitesmoke",
                                textDecoration: "none",
                                wordBreak: "break-all",
                              }}
                            >
                              {element.whatsappNumber}
                            </a>
                          </div>
                        )}
                      </div>

                      <div className="m-4 d-flex flex-wrap justify-content-center row row-cols-2 row-cols-sm-3 row-cols-md-4">
                        {element.twitterUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 2px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={element.twitterUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaTwitter
                                size={40}
                                style={{ color: "#1da1f2" }}
                              />
                            </a>
                          </div>
                        )}
                        {element.phoneNumber && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={`sms:${element.phoneNumber}`}
                              rel="noreferrer"
                            >
                              <FaComment
                                size={40}
                                style={{ color: "#1da1f2" }}
                              />
                            </a>
                          </div>
                        )}
                        {element.whatsappNumber && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={`whatsapp://send?phone=${element.whatsappNumber}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaWhatsapp
                                size={40}
                                style={{ color: "green" }}
                              />
                            </a>
                          </div>
                        )}
                        {element.instagramUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={element.instagramUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaInstagram
                                size={40}
                                style={{ color: "#e4405f" }}
                              />
                            </a>
                          </div>
                        )}
                        {element.linkedinUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={element.linkedinUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaLinkedin
                                size={40}
                                style={{ color: "#0a66c2" }}
                              />
                            </a>
                          </div>
                        )}
                        {element.snapChatUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={element.snapChatUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaSnapchatGhost
                                size={40}
                                style={{ color: "#fffc00" }}
                              />
                            </a>
                          </div>
                        )}
                        {element.youtubeUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={element.youtubeUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaYoutube
                                size={40}
                                style={{ color: "#ff0000" }}
                              />
                            </a>
                          </div>
                        )}
                        {element.yahooUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={element.yahooUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaYahoo size={40} style={{ color: "#720e9e" }} />
                            </a>
                          </div>
                        )}
                        {element.pinterestUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={element.pinterestUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaPinterest
                                size={40}
                                style={{ color: "#bd081c" }}
                              />
                            </a>
                          </div>
                        )}
                        {element.facebookUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={element.facebookUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <FaFacebook
                                size={40}
                                style={{ color: "#3b5998" }}
                              />
                            </a>
                          </div>
                        )}
       {element.googleDriveUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                        <a
        href={element.googleDriveUrl}
        rel="noreferrer"
        target="_blank"
        >
    
    <img src="https://upload.wikimedia.org/wikipedia/commons/1/12/Google_Drive_icon_%282020%29.svg"
        alt="Drive"
        style={{
            width: "30px",
            height: "30px",
            
        }}/>
        </a>
                          </div>
                        )}

                        {element.email && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
                              href={`mailto:${element.email}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              <img
                                src="https://www.gstatic.com/images/icons/material/product/2x/gmail_48dp.png"
                                alt="Gmail"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  color: "#D44638",
                                }}
                              />
                            </a>
                          </div>
                        )}
                      </div>
                      <hr></hr>
                      {User.aboutus && (
                        <div>
                          <h4>About Us</h4>
                        </div>
                      )}

{ element.aboutUs && (
                  <div className="row">

                    <div>
                      <h3 className="text-center text-white">About Us</h3>
                    </div>
                    <div>
                      {element.aboutUs.split('\n').map((para, index) => (
                        <p className={`${styles.aboutus}`} style={{ marginLeft: '18px', marginRight: '10px' }} key={index}>
                          {para}
                        </p>
                      ))}
                    </div>
                  </div>
          )}

                      <hr></hr>
                      <div className="padding">
                        <center>
                          {" "}
                          <h4>Qr Code</h4>
                        </center>
                      </div>

                      <div>
                        <center>
                          <img
                            src={`data:image/jpeg;base64,${element.qrcode}`}
                            alt={"logo"}
                            style={{
                              maxWidth: "90%",
                              marginLeft: "-4px",
                              height: "auto",
                              marginBottom: "20px",
                            }}
                          />

                          <div className=" btn-primary-share">
                            <div className="btn-primary-share">
                              <a
                                href={`data:image/png;base64,${element.qrcode}`}
                                download="qr_code.png"
                                className="btn btn-primary"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              >
                                {hovered ? (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <BsFillArrowDownSquareFill
                                        size={20}
                                        className="mr-2"
                                      />
                                      <span className=" white-text">
                                        {formatFileSize(element.qrcode.length)}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  "Download QR Code"
                                )}
                              </a>
                            </div>
                          </div>
                        </center>
                      </div>

                      <hr></hr>
                      <div
                        style={{
                          color: " black",
                        }}
                      >
                        <NewForm key1={element.email} />
                      </div>

                      <div
                        style={{
                          marginTop: "50px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <h5 className=" text-center">Powered by</h5>
                          <a
                            href="https://www.aptits.com/Home"
                            style={{ color: "white", marginLeft: "10px" }}
                          >
                            <img
                              src="../assests/APTLOGO.png"
                              alt="APT IT SOLUTIONS Logo"
                              style={{
                                display: "block",
                                width: "80px",
                                height: "40px",
                              }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          } else {
            return (
              <>
                <div style={{ minHeight: "100vh" }}>
                  <div
                    className="container text-center"
                    style={{
                      minHeight: "100vh",
                      width: "100%",
                      maxWidth: "600px",
                      backgroundColor: "cornsilk",
                    }}
                  >
                    <h3>APT KARD</h3>

                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginTop: "30px" }}
                    >
                      {element.photo && (
                        <div className="img-logo mr-10">
                          <img
                            src={`data:image/jpeg;base64,${element.photo}`}
                            alt=""
                            style={{
                              borderRadius: "60px",
                              width: "100px",
                              height: "130px",
                              marginLeft: "30px",
                            }}
                          />
                        </div>
                      )}

                      {!element.photo && (
                        <div className="col-sm-12 text-center">
                          <h4 style={{ marginTop: "10px" }}>
                            {element.fullName}&nbsp;&nbsp;{" "}
                          </h4>
                          <h6>{element.designation}</h6>
                        </div>
                      )}

                      {element.photo && (
                        <div
                          className={`col-sm-5 ${
                            !element.fullName ? "text-center" : ""
                          }`}
                          style={{
                            marginTop: "0px",
                            marginBottom: "70px",
                            margin: "20px",
                          }}
                        >
                          {element.fullName && <h3>{element.fullName}</h3>}
                          <h6>{element.designation}</h6>
                        </div>
                      )}
                    </div>

                    <div
                      className="card mt-3"
                      style={{ backgroundColor: "cornsilk" }}
                    >
                      <div className="card-body">
                        <div className="mt-3 ">
                          <p>
                            Your account is temporarily suspended due to a low
                            balance. <br></br>
                            Please recharge your account to reactivate your
                            services.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-6">
                        <img
                          src="..\assests\APTLOGO-removebg-preview.png"
                          className="rounded img-fluid"
                          alt="LOGO"
                          style={{ width: "120px" }}
                        />
                      </div>
                      <div className="col-6">
                        <img
                          src="..\assests\AptCard-removebg-preview.png"
                          className="rounded img-fluid"
                          alt="APT Card"
                          style={{ width: "180px" }}
                        />
                      </div>
                    </div>

                    <div
                      className="card mt-4"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <div className="card-body">
                        <h4>FOR RECHARGE</h4>
                        <h6>Visit</h6>

                        <div
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <div className="mb-2">
                              <a
                                href="https://www.aptits.com/Home"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ wordBreak: "break-all" }}
                              >
                                https://www.aptits.com/Home
                              </a>
                            </div>

                            <div className="mb-2">
                              <p>OR</p>
                            </div>

                            <div className="mb-2">
                              <a
                                href="https://www.linkedin.com/company/apt-it-solution/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ wordBreak: "break-all" }}
                              >
                                https://www.linkedin.com/company/apt-it-solution/
                              </a>
                            </div>

                            <div className="mb-2">
                              <p>OR</p>
                            </div>

                            <div className="mb-2">
                              <a
                                href="https://www.linkedin.com/in/sai-srinivas-57711626a/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ wordBreak: "break-all" }}
                              >
                                https://www.linkedin.com/in/sai-srinivas-57711626a/
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }
        })}
      </>
    </div>
  );
};

export default Template1;

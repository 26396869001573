import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
 import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin ,FaEnvelope } from 'react-icons/fa';
import { BsFillArrowDownSquareFill } from "react-icons/bs";
import { FaComment, FaCopy, FaShare, FaWhatsapp } from 'react-icons/fa';
import {BiShareAlt} from 'react-icons/bi';

import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import styles from "./Pop.module.css"                 
import EmployeePage from './NewEmployee';
import jsQR from 'jsqr';

                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
function User(props) {
  console.log(props);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [qrCodeData, setQRCodeData] = useState([]);
  const [url, setUrl] = useState('');
  const [hovered, setHovered] = useState(false);

  const variableofusername = props.key2;
  const variableofFullId = props.key3;

  const handleGenerateQRCode = () => {
    const apiUrl = `https://backend.aptkard.in/aptkard/${variableofFullId}/${variableofusername}`;
    console.log("API URL:", apiUrl);
    axios.get(apiUrl)
      .then(response => {
        const { data } = response;
        setQRCodeData(data);
        console.log(data);

        // Decode the QR code image
        const imageData = new Image();
        imageData.src = `data:image/jpeg;base64,${data[0].qrcode}`;
        imageData.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = imageData.width;
          canvas.height = imageData.height;
          const context = canvas.getContext('2d');
          context.drawImage(imageData, 0, 0, imageData.width, imageData.height);
          const imageDataUrl = canvas.toDataURL();
          const code = jsQR(new Uint8ClampedArray(context.getImageData(0, 0, canvas.width, canvas.height).data), canvas.width, canvas.height);
          if (code) {
            const url = code.data;
            // Set the decoded URL as the value of the url state variable
            setUrl(url);
            // Set the value of the input field to the decoded URL
            document.getElementById("myInputField").value = url;
          } else {
            console.log("QR code not found");
          }
        };
      })
      .catch(error => console.error(error));
  };



  const openModal = () => {
    setModalIsOpen(true);
    handleGenerateQRCode();
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };


  const handleMouseEnter = () => {
    setHovered(true);
  };



  const handleMouseLeave = () => {
    setHovered(false);
  };

  // const handleShare = () => {
  //   const url = window.location.href;

  //   if (navigator.share) {
  //     navigator.share({ title: 'Share this page', url })
  //       .then(() => console.log('Shared successfully.'))
  //       .catch((error) => console.error('Error sharing:', error));
  //   } else {
  //     // Fallback for devices/browsers that do not support the Web Share API
  //     console.log('Fallback share functionality');
  //     // Add your custom share logic here (e.g., showing a share dialog or copying the URL to the clipboard)
  //   }
  // };

  const handleShareClick = () => {
    const url = window.location.href;

    if (navigator.share) {
      navigator.share({ title: 'Share this page', url })
        .then(() => console.log('Page shared successfully.'))
        .catch((error) => console.error('Error sharing page:', error));
    } else {
      // Fallback behavior for browsers that do not support the share API
      // You can open a share dialog in a new tab or implement other fallback options here
      console.log('Share API not supported.');
    }
  }

  // const ShareButton = () => (
  //   <a href="#" onClick={handleShare}>
  //     <FaShare size={32} />
  //   </a>
  // );



  function sharePage() {
    const url = window.location.href;

    if (navigator.share) {
      navigator.share({ title: 'Share this page', url })
        .then(() => console.log('Page shared successfully.'))
        .catch((error) => console.error('Error sharing page:', error));
    } else {
      console.log('Web Share API not supported.');
      // Implement a fallback behavior for devices/browsers that do not support Web Share API.
      // For example, you can open a share dialog in a new tab using a social sharing library.
      // Alternatively, you can copy the URL to the clipboard using a clipboard API.
    }
  }

  const formatFileSize = (fileSize) => {
    if (fileSize < 1024) {
      return `${fileSize} B`;
    } else if (fileSize < 1024 * 1024) {
      const fileSizeInKB = Math.round(fileSize / 1024);
      return `${fileSizeInKB} KB`;
    } else {
      const fileSizeInMB = Math.round(fileSize / (1024 * 1024));
      return `${fileSizeInMB} MB`;
    }
  };



  return (
    <>


      <div>
        <a onClick={openModal} ><BiShareAlt size={20}/>Share Profile</a>

        <Modal
          isOpen={modalIsOpen}
          contentLabel="Example Modal"
          className="modal-dialog custom-modal"
          style={{
            overlay: {
              background: 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',


            },
            content: {
              background: 'none',
              border: 'none',
              width: '100%',
              maxWidth: '410px',
              height: '100%',
              maxHeight: '1000px',
              margin: '0',
              padding: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '0', // Added to position at the top
            },

          }}




        >
          <div className="modal-content align-center" style={{ backgroundColor: 'rgb(480,480,540)' }}>
            <div className="modal-header">
              <h5 className="modal-title text-center" style={{ whiteSpace: 'pre', marginTop: "10px" }}>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Share My Digital Card</h5>
              <button type="button" id="close" className="btn-close" aria-label="Close" onClick={closeModal} style={{ marginBottom: "10px", opacity: 1, padding: "20px" }}></button>

              {/* <button id="close" aria-label="close" formnovalidate>X</button> */}

            </div>
            <div className="modal-body justify-content-center">
              <div className="d-flex justify-content-center">
                <div className="d-flex flex-column align-items-center">
                  {qrCodeData[0] && <img src={`data:image/jpeg;base64,${qrCodeData[0].qrcode}`} alt="QR Code" />}
                  {/* {qrCodeData[0] && <div className="my-3" style={{ marginRight:"80px" }}><a href={`data:image/jpeg;base64,${qrCodeData[0].qrcode}`} download="qr_code.png" className="btn btn-primary">Download QR Code</a></div>} */}

                  <div className="my-3" style={{ marginRight: "125px" }}>
                    {qrCodeData && qrCodeData[0] && qrCodeData[0].qrcode ? (
                      <a
                        href={`data:image/jpeg;base64,${qrCodeData[0].qrcode}`}
                        download="qr_code.png"
                        className="btn btn-primary"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        {hovered ? (
                          <>
                            <div className="d-flex align-items-center">
                              <BsFillArrowDownSquareFill size={20} className="mr-2" />
                              <span className=" white-text" style={{ color: 'white' }}>
                                {formatFileSize(qrCodeData[0].qrcode.length)}
                              </span>
                            </div>

                          </>
                        ) : (
                          "Download QR Code"
                        )}
                      </a>
                    ) : null}
                  </div>
                </div>

              </div>


              <div>
                <center>
                  <div className="d-flex flex-wrap justify-content-center row row-cols-2 row-cols-sm-3 row-cols-md-4 mt-2">

                    {/* {qrCodeData[0] && qrCodeData[0].twitterUrl && (
                       <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                      <a href={qrCodeData[0].twitterUrl} rel="noreferrer" target="_blank">
                        <FaTwitter size={40} style={{ color: "#1da1f2" }} />
                      </a>
                      </div>
                    )} */}
                    {qrCodeData[0] && qrCodeData[0].whatsappNumber && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={`whatsapp://send?phone=${qrCodeData[0].whatsappNumber}`} rel="noreferrer" target="_blank">
                          <FaWhatsapp size={40} style={{ color: "green" }} />
                        </a>
                      </div>
                    )}

                    {qrCodeData[0] && qrCodeData[0].phoneNumber && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={`sms:${qrCodeData[0].phoneNumber}`} rel="noreferrer" >
                          <FaComment size={40} style={{ color: "#1da1f2" }} />
                        </a>
                      </div>
                    )}




                    {/* {qrCodeData[0] && qrCodeData[0].snapChatUrl && (
                        <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                      <a href={qrCodeData[0].snapChatUrl} rel="noreferrer" target="_blank">
                        <FaSnapchatGhost size={40} style={{ color: "#fffc00" }} />
                      </a>
                      </div>
                    )} */}

                    {/* {qrCodeData[0] && qrCodeData[0].yahooUrl && (
                        <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                      <a href={qrCodeData[0].yahooUrl} rel="noreferrer" target="_blank">
                        <FaYahoo size={40} style={{ color: "#720e9e" }} />
                      </a>
                      </div>
                    )} */}

                    {/* {qrCodeData[0] && qrCodeData[0].facebookUrl && (
                        <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                      <a href={qrCodeData[0].facebookUrl} rel="noreferrer" target="_blank">
                        <FaFacebook size={40} style={{ color: "#3b5998" }} />
                      </a>
                      </div>
                    )} */}
                    {qrCodeData[0] && qrCodeData[0].email && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={`mailto:${qrCodeData[0].email}`} rel="noreferrer" target="_blank">
                          <img
                            src="https://www.gstatic.com/images/icons/material/product/2x/gmail_48dp.png"
                            alt="Gmail"
                            style={{
                              width: "40px",
                              height: "40px",
                              color: "#D44638",
                            }}
                          />
                        </a>
                      </div>
                    )}


 <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>

 <a href="#" onClick={handleShareClick}>
      <FaShare size={32}/>
    </a> 


 </div>
  

                  </div>
                </center>
              </div>



              <div style={{ display: "flex", justifyContent: "space-between" }}>

                <input type="text" id="myInputField" className="form-control" value={url} readOnly />

              <button className="btn btn-outline-secondary" type="button" onClick={() => navigator.clipboard.writeText(url)}>
                  <FaCopy size={16} />
                </button> 

             

              </div>
            </div>

          </div>
        </Modal>


      </div>

    </>
  );
}

export default User;
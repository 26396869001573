import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Registration from './Screens/Registration' 
import { BrowserRouter } from 'react-router-dom';
import ViewPanel from './Screens/ViewPanel';
import Header from './Components/Header';
import User from './Components/ShareButton';
createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
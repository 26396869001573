import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import styles from './ViewPanel.module.css'
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import 'animate.css';
import 'react-phone-input-2/lib/style.css';
import { Skeleton } from '@mui/material';

const ViewPanel = () => {
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [fallbackUI, setFallbackUI] = useState(false); // Added fallbackUI state
  const [currentPage, setCurrentpage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const navigate = useNavigate('');

  const fetchData = async () => {
    setIsLoadingMore(true);
    try {
      const response = await fetch('https://backend.aptkard.in/aptkard/allusers');
      if (response.ok) {
        const data = await response.json();
        setUserData(prevUserData => data);
        setFilteredData(prevFilteredData => data);

        setFallbackUI(false); // Clear fallback UI when data is fetched successfully
      } else {
        setFallbackUI(true); // Show fallback UI when there's an error
      }
    } catch (error) {
      console.log(error);
      setFallbackUI(true); // Show fallback UI when there's an error
    }
    setIsLoadingMore(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);

  const totalPages = Math.ceil(filteredData.length / postsPerPage);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentpage(pageNumber);
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const data = userData.filter((item) => {
      return (
        item.id.toString().startsWith(term) ||
        item.fullName.toLowerCase().startsWith(term.toLowerCase()) ||
        item.designation.toLowerCase().startsWith(term.toLowerCase()) ||
        item.phoneNumber.toLowerCase().startsWith(term.toLowerCase()) ||
        item.fullName.toLowerCase().includes(term.toLowerCase())
      );
    });
    setFilteredData(data);
    setCurrentpage(1); // Reset the current page to the first page after search
  };

  const handleView = (id, username) => {
    navigate(`/${id}/${username}`);
  }

  const handleDelete = (user) => {
    Swal.fire({
      title: 'Confirmation',
      text: 'Are you sure you want to delete?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      customClass: {
        popup: 'animate__animated animate__fadeInDown',
        confirmButton: 'animate__animated animate__fadeIn',
        cancelButton: 'animate__animated animate__fadeIn',
      },
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(user);
      }
    });
  };

  const deleteUser = async (user) => {
    try {
      const response = await fetch(`https://backend.aptkard.in/aptkard/${user.id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setUserData((prevUserData) => prevUserData.filter((u) => u.id !== user.id));
        setFilteredData((prevFilteredData) => prevFilteredData.filter((u) => u.id !== user.id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (user) => {
    setSelectedUser((prevUserData) => ({
      ...prevUserData,
      ...user,
      fileData: { name: user.logo, url: user.logo },
      photoData: { name: user.photo, url: user.photo },
    }));
    setShowEditDialog(true);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedUser((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedUser((prevUserData) => ({
      ...prevUserData,
      fileData: {
        name: file.name,
        url: URL.createObjectURL(file),
        file: file,
        photo: file, // Store the file as photo as well
      },
    }));
  };

  const handlePhotoChange = (event) => {
    const photo = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setSelectedUser((prevUserData) => ({
        ...prevUserData,
        photoData: {
          file: photo, // Store the file itself
          name: photo.name || reader.result.split("/").pop(), // Extract the file name from the FileReader result
          url: URL.createObjectURL(photo),
          file: photo, // Store the file as file as well
        },
      }));
    };

    if (photo) {
      reader.readAsDataURL(photo);
    }
  };


  const handleRemoveFile = () => {
    // Show the confirmation dialog before proceeding with file removal
    Swal.fire({
      icon: 'warning',
      title: 'Are you really sure you want to delete the LOGO?',
      text: 'NOTE: It cannot be recovered once deleted',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        container: 'my-swal-container', // Define a custom class for the container
      },
    }).then((result) => {
      // If the user clicks the "Confirm" button
      if (result.isConfirmed) {
        setSelectedUser((prevUserData) => ({
          ...prevUserData,
          fileData: {
            name: '',
            url: '',
            file: null,
            fileRemoved: true,
          },
        }));

        const deleteFile = async () => {
          try {
            const response = await fetch(`https://backend.aptkard.in/aptkard/updateLogo/${selectedUser.id}`, {
              method: 'PUT',
            });
            if (response.ok) {
              Swal.fire({
                icon: 'success',
                title: 'The LOGO was deleted successfully and cannot be recovered anymore',
              });
              fetchData();
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Failed to delete the LOGO',
              });
            }
          } catch (error) {
            console.log('Error deleting the LOGO:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error deleting the LOGO',
            });
          }
        };

        deleteFile();
      }
    });
  };

  const handleRemovePhoto = (user) => {
    // Show the confirmation dialog before proceeding with photo deletion
    Swal.fire({
      icon: 'warning',
      title: 'Are you really sure you want to delete the photo?',
      text: 'NOTE: It cannot be recovered once deleted',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      // If the user clicks the "Confirm" button
      if (result.isConfirmed) {
        setSelectedUser((prevUserData) => ({
          ...prevUserData,
          photoData: {
            name: '',
            url: '',
            file: null,
            photoRemoved: true,
          },
        }));

        const deletePhoto = async () => {
          try {
            const response = await fetch(`https://backend.aptkard.in/aptkard/updatePhoto/${selectedUser.id}`, {
              method: 'PUT',
            });
            if (response.ok) {
              Swal.fire({
                icon: 'success',
                title: 'The photo was deleted successfully and cannot be recovered anymore',
              });
              fetchData();
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Failed to delete the photo',
              });
            }
          } catch (error) {
            console.log('Error deleting the photo:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error deleting the photo',
            });
          }
        };

        deletePhoto();
      }
    });
  };

  // Display file image using SweetAlert
  const displayFileImage = () => {
    if (selectedUser.fileData.url) {
      Swal.fire({
        title: 'File Image',
        imageUrl: `data:image/jpeg;base64, ${selectedUser.fileData.url}`,
        imageWidth: 300,
        imageHeight: 300,
        imageAlt: 'File Image',
      });
    }
  };

  // Display photo image using SweetAlert
  const displayPhotoImage = () => {
    if (selectedUser.photoData.url) {
      Swal.fire({
        title: 'Photo Image',
        imageUrl: `data:image/jpeg;base64, ${selectedUser.photoData.url}`,
        imageWidth: 300,
        imageHeight: 300,
        imageAlt: 'Photo Image',
      });
    }
  };


  const handleSave = async () => {
    if (!selectedUser.fullName) {
      console.log("Full name is required");
      return;
    }



    const formData = new FormData();
    formData.append("fullName", selectedUser.fullName);
    formData.append("designation", selectedUser.designation);
    formData.append("phoneNumber", selectedUser.phoneNumber);
    formData.append("whatsappNumber", selectedUser.whatsappNumber);
    formData.append("linkedinUrl", selectedUser.linkedinUrl);
    formData.append("location", selectedUser.location);
    formData.append("email", selectedUser.email);
    formData.append("websiteUrl", selectedUser.websiteUrl);
    formData.append("instagramUrl", selectedUser.instagramUrl);
    formData.append("facebookUrl", selectedUser.facebookUrl);
    formData.append("twitterUrl", selectedUser.twitterUrl);
    formData.append("yahooUrl", selectedUser.yahooUrl);
    formData.append("pinterestUrl", selectedUser.pinterestUrl);
    formData.append("youtubeUrl", selectedUser.youtubeUrl);
    formData.append("createdBy", selectedUser.createdBy);
    formData.append("snapChatUrl", selectedUser.snapChatUrl);
    // formData.append("address", selectedUser.address);
    formData.append("aboutUs", selectedUser.aboutUs);
    formData.append("status", selectedUser.status);
    formData.append("googleDriveUrl",selectedUser.googleDriveUrl);
    formData.append('templateStyle', selectedUser.templateStyle);
    // Check if file has been removed
    if (selectedUser.fileData.fileRemoved) {
      formData.append("file", ""); // Send an empty value to indicate file removal
    } else if (selectedUser.fileData.file) {
      formData.append("file", selectedUser.fileData.file);
    }

    // Check if photo has been removed
    if (selectedUser.photoData.photoRemoved) {
      formData.append("photo", ""); // Send an empty value to indicate photo removal
    } else if (selectedUser.photoData.file) {
      formData.append("photo", selectedUser.photoData.file);
    }
    try {
      const response = await fetch(`https://backend.aptkard.in/aptkard/update/${selectedUser.id}`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        setUserData((prevUserData) => {
          const updatedData = prevUserData.map((user) => {
            if (user.id === selectedUser.id) {
              return selectedUser;
            }
            return user;
          });
          return updatedData;
        });

        // // Display window alert
        // window.alert('Successfully updated!');

        Swal.fire({
          title: 'Updated!',
          text: 'Successfully updated data!',
          icon: 'success',
        });
        // Auto refresh after 2 seconds

        // Close the edit dialog
        setShowEditDialog(false);
        fetchData();

      } else {
        console.log('Failed to update user:', response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!selectedUser.fullName) {
      console.log("Full name is required");
      return;
    }
    if (selectedUser.aboutUs.length > 600) {
      console.log("About Us cannot exceed 600 characters");
      return;
    }

    const formData = new FormData();
    formData.append("fullName", selectedUser.fullName);
    formData.append("designation", selectedUser.designation);
    formData.append("phoneNumber", selectedUser.phoneNumber);
    formData.append("whatsappNumber", selectedUser.whatsappNumber);
    formData.append("linkedinUrl", selectedUser.linkedinUrl);
    formData.append("location", selectedUser.location);
    formData.append("email", selectedUser.email);
    formData.append("websiteUrl", selectedUser.websiteUrl);
    formData.append("instagramUrl", selectedUser.instagramUrl);
    formData.append("facebookUrl", selectedUser.facebookUrl);
    formData.append("twitterUrl", selectedUser.twitterUrl);
    formData.append("yahooUrl", selectedUser.yahooUrl);
    formData.append("pinterestUrl", selectedUser.pinterestUrl);
    formData.append("youtubeUrl", selectedUser.youtubeUrl);
    formData.append("createdBy", selectedUser.createdBy);
    formData.append("snapChatUrl", selectedUser.snapChatUrl);
    // formData.append("address", selectedUser.address);
    formData.append("aboutUs", selectedUser.aboutUs);
    formData.append("status", selectedUser.status ? "active" : "inactive");
    formData.append("googleDriveUrl",selectedUser.googleDriveUrl);
    formData.append("templateStyle", selectedUser.templateStyle === "Template1" ? "Template1" : (selectedUser.templateStyle === "Template2" ? "Template2" : "Template3"));
    // Check if file has been removed
    if (selectedUser.fileData.fileRemoved) {
      formData.append("file", ""); // Send an empty value to indicate file removal
    } else if (selectedUser.fileData.file) {
      formData.append("file", selectedUser.fileData.file);
    }

    // Check if photo has been removed
    if (selectedUser.photoData.photoRemoved) {
      formData.append("photo", ""); // Send an empty value to indicate photo removal
    } else if (selectedUser.photoData.file) {
      formData.append("photo", selectedUser.photoData.file);
    }

    try {
      const response = await fetch(`https://backend.aptkard.in/aptkard/update/${selectedUser.id}`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        const updatedUser = await response.json();
        setUserData((prevUserData) =>
          prevUserData.map((user) => (user.id === updatedUser.id ? updatedUser : user))
        );
        setFilteredData((prevFilteredData) =>
          prevFilteredData.map((user) => (user.id === updatedUser.id ? updatedUser : user))
        );
        setShowEditDialog(false);
        setSelectedUser(null);

        // Display success alert message
        Swal.fire({
          title: 'Data Updated!',
          text: 'Successfully updated data!',
          icon: 'success',
        });
      } else {
        console.log('Failed to update user:', response.statusText);
      }
      fetchData();

    } catch (error) {
      console.log(error);
    }
  };

  const handleGoto = () => {
    navigate('/registration'); // Navigate to '/register' page
  }

  return (
    <>


      {fallbackUI ? (
        <div className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '100vh' }}
        >
          <div style={{
            background: 'linear-gradient(45deg, #FF6B6B, #FFC947)',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'
          }}

          >
            <h3 style={{
              color: 'white',
              fontSize: '24px',
              fontFamily: 'Arial, sans-serif',
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)'
            }}
            >
              Failed to fetch data. Please try again later. may be server issue.
            </h3>
          </div>
        </div>

      ) : (

        <>
          <div className='mx-2 mt-2' style={{ maxHeight: '100%', paddingLeft: '100px', paddingTop: '8px' }}>
            <div className='row' style={{ width: '100%' }}>
              <div className='col-4'>
                <h4 className='d-flex justify-content-center'>SUBSCRIBERS' LIST</h4>
              </div>
              <div className='col-4'>
                <input
                  type="text"
                  name="search"
                  value={searchTerm}
                  className="form-control"
                  onChange={handleSearch}
                  placeholder="Search by id, name, phoneNumber or designation"
                />
              </div>

              <div className={`${styles.pullRight} d-flex align-items-center justify-content-center  col-4`}>
                <button onClick={handleGoto} className='btn btn-primary btn-lg'>
                  ADD NEW USER
                </button>
              </div>
            </div>

            <div className='card mt-3' style={{ padding: "10px" }}>
              <div className='card-body'>
                <div style={{ maxWidth: '100%', maxHeight: '68vh', overflow: 'auto', overflowY: 'scroll' }}>
                  <table className='table table-responsive table-bordered table-striped table-hover table-dark'>
                    <thead style={{ position: 'sticky', top: '0', textAlign: 'center' }}>
                      <tr>
                        <th>S.No</th>
                        <th>FullName</th>
                        <th>Id</th>                      
                        <th>Password</th>
                        <th>Email</th>
                        <th>PhoneNumber</th>
                        <th>Address</th>
                        <th>CreatedBy</th>
                        <th>Logo</th>
                        <th>Action</th>
                        <th>Delete</th>
                        <th>Preview</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className='table table-responsive' >
                    {!isLoadingMore ? (

                  
                      currentPosts.map((getUser, index) => (
                        <tr key={index + 1}>
                          <td style={{ width: '1px', wordWrap: 'break-word' }}>{index + 1}</td>
                          <td style={{ width: '10px', wordWrap: 'break-word' }}>{getUser.fullName}</td>
                          <td >{getUser.id}</td>
                          <td>{getUser.password}</td>
                          <td style={{ maxWidth: '100px', wordWrap: 'break-word' }}>{getUser.email}</td>
                          <td >{getUser.phoneNumber}</td>
                          <td style={{ whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis", maxWidth: '100px', wordWrap: 'break-word' }}>{getUser.location}</td>
                          <td style={{ maxWidth: '40px', wordWrap: 'break-word' }}>{getUser.createdBy}</td>
                          <td>
                            <img src={`data:image/jpeg;base64,${getUser.logo}`} style={{ width: '80px', height: '80px' }} alt='User logo' />
                          </td>
                          <td>
                            <button onClick={() => handleEdit(getUser)} className="icon-button">
                              <FontAwesomeIcon icon={faEdit} /> Update
                            </button>
                          </td>
                          <td>
                            <button onClick={() => handleDelete(getUser)} className="icon-button">
                              <FontAwesomeIcon icon={faTrashAlt} />
                              <span>Delete</span>
                            </button>
                          </td>
                          <td>
                            <button onClick={() => handleView(getUser.id, getUser.username)}>
                              <FontAwesomeIcon icon={faEye} /> View
                            </button>
                          </td>
                          <td>
                            {getUser.status === 'active' ? (
                              <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'green' }}></div>
                            ) : getUser.status === 'deactive' ? (
                              <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red' }}></div>
                            ) : null}
                            {getUser.status}
                          </td>
                        </tr>
                      ))) : (

                    
                       

                       <tr >
                          <td ><Skeleton variant="rectangular" width={20} height={60} /></td>
                          <td><Skeleton variant="rectangular" width={20} height={60} /></td>
                          <td ><Skeleton variant="rectangular" width={20} height={60} /></td>
                          <td><Skeleton variant="rectangular" width={20} height={60} /></td>
                          <td ><Skeleton variant="rectangular" width={20} height={60} /></td>
                          <td ><Skeleton variant="rectangular" width={20} height={60}/></td>
                          <td ><Skeleton variant="rectangular" width={20} height={60} /></td>
                          <td ><Skeleton variant="rectangular" width={20} height={60} /></td>
                          <td>
                          <Skeleton variant="rectangular" width={20} height={60} />
                     
                          </td>
                          <td>
                          <Skeleton variant="rectangular" width={20} height={60} />
                          </td>
                          <td>
                          <Skeleton variant="rectangular" width={20} height={60} />
                          </td>
                          <td>
                          <Skeleton variant="rectangular" width={20} height={60} />
                          </td>
                          <td>
                          <Skeleton variant="rectangular" width={20} height={60}/>
                          </td>
                        </tr>
                     
                


                      )
                            }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <ul className='pagination d-flex justify-content-center mt-3 '>
              {pageNumbers.map((number) => (
                <li
                  key={number}
                  className={`page-item${currentPage === number ? ' active' : ''}`}
                >
                  <button
                    className='page-link'
                    onClick={() => handlePageChange(number)}
                  >
                    {number}
                  </button>
                </li>
              ))}
            </ul>




            {/* THIS IS FOR POPUP UPDATE PURPOSE */}
            {showEditDialog && selectedUser && (
              <Modal show={showEditDialog} onHide={() => setShowEditDialog(false)}   style={{   overflowY : 'scroll'}} >
                <Modal.Header closeButton>
                  <Modal.Title>{selectedUser ? 'Edit User' : 'Add User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{   overflowY : 'scroll', height : '900px'}}  >
                  <form onSubmit={handleFormSubmit} >

                    <div className="form-group" >
                      <label htmlFor="fullName">Full Name </label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullName"
                        name="fullName"
                        value={selectedUser.fullName || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="designation">Designation</label>
                      <input
                        type="text"
                        className="form-control"
                        id="designation"
                        name="designation"
                        value={selectedUser.designation || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={selectedUser.phoneNumber || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="whatsappNumber">WhatsApp Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="whatsappNumber"
                        name="whatsappNumber"
                        value={selectedUser.whatsappNumber || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="linkedinUrl">LinkedIn URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="linkedinUrl"
                        name="linkedinUrl"
                        value={selectedUser.linkedinUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="location">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        name="location"
                        value={selectedUser.location || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={selectedUser.email || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="websiteUrl">Website URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="websiteUrl"
                        name="websiteUrl"
                        value={selectedUser.websiteUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="instagramUrl">Instagram URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="instagramUrl"
                        name="instagramUrl"
                        value={selectedUser.instagramUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="facebookUrl">Facebook URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="facebookUrl"
                        name="facebookUrl"
                        value={selectedUser.facebookUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="twitterUrl">Twitter URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="twitterUrl"
                        name="twitterUrl"
                        value={selectedUser.twitterUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="googleDriveUrl">Google Drive URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="googleDriveUrl"
                        name="googleDriveUrl"
                        value={selectedUser.googleDriveUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="yahooUrl">Yahoo URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="yahooUrl"
                        name="yahooUrl"
                        value={selectedUser.yahooUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="pinterestUrl">Pinterest URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="pinterestUrl"
                        name="pinterestUrl"
                        value={selectedUser.pinterestUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="youtubeUrl">YouTube URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="youtubeUrl"
                        name="youtubeUrl"
                        value={selectedUser.youtubeUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="createdBy">Created By</label>
                      <input
                        type="text"
                        className="form-control"
                        id="createdBy"
                        name="createdBy"
                        value={selectedUser.createdBy || ''}
                        readOnly
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="snapChatUrl">SnapChat URL</label>
                      <input
                        type="text"
                        className="form-control"
                        id="snapChatUrl"
                        name="snapChatUrl"
                        value={selectedUser.snapChatUrl || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="aboutUs">About Us</label>
                      <textarea
                        className="form-control"
                        id="aboutUs"
                        name="aboutUs"
                        value={selectedUser.aboutUs || ''}
                        onChange={handleInputChange}
                      ></textarea>
                      {selectedUser.aboutUs && selectedUser.aboutUs.length > 4000 && (
                        <p className="error-message text-danger" >About Us cannot exceed 4000 characters</p>
                      )}
                    </div>

                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        className="form-control"
                        id="status"
                        name="status"
                        value={selectedUser.status || ''}
                        onChange={handleInputChange}
                      >
                        <option value="">Select status</option>
                        <option value="active">Active</option>
                        <option value="deactive">Deactive</option>
                      </select>
                      <div style={{ color: 'green' }}>Kindly select "active" or "deactive"</div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="templateStyle">Template Style</label>
                      <select
                        className="form-control"
                        id="templateStyle"
                        name="templateStyle"
                        value={selectedUser.templateStyle || ''}
                        onChange={handleInputChange}
                      >
                        <option value="">Select Template</option>
                        <option value="Template1">Template 1</option>
                        <option value="Template2">Template 2</option>
                        <option value="Template3">Template 3</option>
                        <option value="Template4">Template 4</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="file">File</label>
                      <div>
                        {selectedUser.fileData.url ? (
                          <div>
                            {selectedUser.fileData.name && (
                              <div className="file-details" style={{ whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }}>
                                <p className="file-name" >Existing file: {selectedUser.fileData.name}</p>
                                <button onClick={handleRemoveFile} style={{ color: 'red' }}>Remove File</button>
                              </div>
                            )}
                          </div>
                        ) : (
                          selectedUser.fileData.name && (
                            <div className="file-details" style={{ whiteSpace: 'nowrap' }}>
                              <p className="file-name">Selected file: {selectedUser.fileData.name}</p>
                              <button onClick={handleRemoveFile} style={{ color: 'red' }}>Remove File</button>
                            </div>
                          )
                        )}
                        <input
                          type="file"
                          className="form-control-file"
                          id="file"
                          name="file"
                          onChange={handleFileChange}
                        />
                        {!selectedUser.fileData.url && !selectedUser.fileData.name && (
                          <p>No file selected</p>
                        )}
                        {selectedUser.fileData.url && (
                          <button onClick={displayFileImage} className='btn btn-danger'>View File Image</button>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="photo">Photo</label>
                      <div>
                        {selectedUser.photoData.url ? (
                          <div>
                            <div className="photo-details" style={{ whiteSpace: 'nowrap', overflow: "hidden", textOverflow: "ellipsis" }}>
                              <p className="photo-name" >Existing photo: {selectedUser.photoData.name}</p>
                              <button onClick={handleRemovePhoto} style={{ color: 'red' }}>Remove Photo</button>
                            </div>
                          </div>
                        ) : (
                          selectedUser.photoData.name && (
                            <div className="photo-details" style={{ whiteSpace: 'nowrap' }}>
                              <p className="photo-name">Selected photo: {selectedUser.photoData.name}</p>
                              <button onClick={handleRemovePhoto} style={{ color: 'red' }}>Remove Photo</button>
                            </div>
                          )
                        )}
                        <input
                          type="file"
                          className="form-control-file"
                          id="photo"
                          name="photo"
                          onChange={handlePhotoChange}
                        />
                        {!selectedUser.photoData.url && !selectedUser.photoData.name && (
                          <p>No photo selected</p>
                        )}
                        {selectedUser.photoData.url && (
                          <button onClick={displayPhotoImage} className='btn btn-danger'>View Photo Image</button>
                        )}
                      </div>
                    </div>
                  </form>

                </Modal.Body>
                <Modal.Footer>
                  <button className='btn btn-primary' onClick={handleSave}>
                    Save
                  </button>

                  <button className='btn btn-secondary' onClick={() => setShowEditDialog(false)}>
                    Cancel
                  </button>

                </Modal.Footer>
              </Modal>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ViewPanel;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './ListUserEnrollment.module.css';

const ListUserEnrollment = () => {
    const [cardApplications, setCardApplications] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        async function fetchCardApplications() {
            try {
                const response = await axios.get('https://backend.aptkard.in/api/all');
                setCardApplications(response.data);
            } catch (error) {
                console.error('Error fetching card applications:', error);
            }
        }
        fetchCardApplications();
    }, []);

    const filteredCardApplications = cardApplications.filter(application => {
        return (
            application.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            application.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            application.mobileNumber.toString().includes(searchTerm) ||
            application.location.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredCardApplications.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className={styles['card-application-list-container']}>
            <h2 className={styles['list-heading']}>Card Application List</h2>
            <div className={styles['search-container']}>
                <input
                    type="text"
                    className={`form-control ${styles['search-input']}`}
                    placeholder="Search by name, email, mobile number, or location"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                />
            </div>
            <div className={styles['application-table-container']}>
                <table className={styles['application-table']}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile Number</th>
                            <th>Number of Cards</th>
                            <th>Types of Cards</th>
                            <th>Location</th>
                            <th>Note</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map(application => (
                            <tr key={application.id}>
                                <td>{application.name}</td>
                                <td>{application.email}</td>
                                <td>{application.mobileNumber}</td>
                                <td>{application.noOfCards}</td>
                                <td>{application.typeOfCard}</td>
                                <td>{application.location}</td>
                                <td>{application.note}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className={styles['pagination-controls']}>
                    <button
                        className={`${styles['pagination-button']}`}
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span>{currentPage}</span>
                    <button
                        className={`${styles['pagination-button']}`}
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={indexOfLastItem >= filteredCardApplications.length}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ListUserEnrollment;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

const ExchangeContact = (props) => {
    const [msgBody, setMsgBody] = useState('');
    const [subject, setSubject] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [sharerName, setFname] = useState('');
    const [sharerEmail, setEmail] = useState('');
    const [sharerMobile, setMobile] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);
    const recipient = props.email;

    useEffect(() => {
        const clearMessages = () => {
            setErrorMsg('');
            setSuccessMsg('');
        };

        const timeout = setTimeout(clearMessages, 5000); // Clear messages after 5 seconds

        return () => clearTimeout(timeout); // Clean up the timeout on component unmount
    }, []);

    const save = (event) => {
        event.preventDefault();
        setIsBuffering(true)
        if (!recipient || !msgBody || !sharerName || !subject || !sharerMobile) {
            setIsBuffering(false);
            setErrorMsg('Please fill in all required fields.');
            return;
        }


        axios
            .post('https://backend.aptkard.in/aptkard/SharesendMailWithAttachment', { recipient, msgBody: msgBody, subject, sharerName, sharerEmail, sharerMobile })
            .then((response) => {
                setSuccessMsg('Message sent successfully!');
                setMsgBody('');
                setSubject('');
                setAttachment(null);
                setFname('');
                setEmail('');
                setMobile('');
                setIsBuffering(false);
            })
            .catch((error) => {
                setIsBuffering(false);
                setErrorMsg('Failed to send message.');
            });
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };


    return (
        <div>
            <a onClick={openModal}>Exchange Contact</a>

            <Modal
                isOpen={modalIsOpen}
                contentLabel="Example Modal"
                className="modal-dialog custom-modal"
                style={{
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '100%',
                        maxWidth: '510px',
                        height: '100%',
                        maxHeight: '1000px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },

                }}
            >
                <div className='card' style={{ backgroundColor: "rgb(480,480,540)" }}>


                    <div className="modal-content">
                        <div className="modal-header">

                            <div className='d-flex container'>


                                <div className=' row'>
                                    <div className="col">
                                        <h3 className="modal-title d-flex justify-content-center">EXCHANGE CONTACT</h3>
                                    </div>
                                    <h6 className="d-flex justify-content-center"><span style={{ color: 'red' }}>*</span> Please fill out the fields below to share your details with {props.fullName}.</h6>

                                </div>
                            </div>

                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}
                            {successMsg && <div className="alert alert-success">{successMsg}</div>}
                            <div className='card'>
                                <form className='m-4'>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="sharerName"
                                            name="sharerName"
                                            placeholder="Name"
                                            className="form-control"
                                            value={sharerName}
                                            onChange={(e) => setFname(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="sharerEmail"
                                            name="sharerEmail"
                                            placeholder="Email"
                                            className="form-control"
                                            value={sharerEmail}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="sharerMobile"
                                            name="sharerMobile"
                                            placeholder="Mobile"
                                            className="form-control"
                                            value={sharerMobile}
                                            onChange={(e) => setMobile(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="subject"
                                            name="subject"
                                            placeholder="subject"
                                            className="form-control"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            id="msgBody"
                                            name="msgBody"
                                            placeholder="Address"
                                            className="form-control"
                                            value={msgBody}
                                            onChange={(e) => setMsgBody(e.target.value)}
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        style={{
                                            width: '100%',
                                            marginTop: '10px',
                                        }}
                                        onClick={save}
                                        disabled={isBuffering}
                                    >
                                        {isBuffering ? 'buffering.....' : 'submit'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ExchangeContact;
import React from "react";
import { FaFacebook, FaPhone, FaTwitter, FaMapMarkerAlt, FaYahoo, FaLink, FaInstagram, FaSnapchatGhost, FaPinterest, FaLinkedin, FaYoutube, FaEnvelope, FaWhatsapp, FaComment, FaGoogleDrive } from 'react-icons/fa';
import { useEffect, useState } from "react";
import { BsFillArrowDownSquareFill } from "react-icons/bs";
import styles from './Template2.module.css';
import ExchangeContact from "../Components/ExchangeContact";
import User from "../Components/ShareButton";
import NewForm from "../Components/EnquiryForm";

const Template2 = (props) => {
  console.log(props);
  const [count, setCount] = useState(null);
  const [users, setUsers] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [showExchangeContactForm, setShowExchangeContactForm] = useState(false);

  const handleExchangeContactClick = () => {
    setShowExchangeContactForm(true);
  };
  
  
  useEffect(() => {
    const fetchData = fetch(
      `https://backend.aptkard.in/aptkard/${props.id}/${props.userName}`
    );
  
    fetchData
      .then((dataResponse) => {
        dataResponse.json().then((data) => {
          setUsers(data);
          console.log(data);
          setCount(data[0].views);
        });
      })
      .catch(() => setUsers("server error/server down"));
  }, [props.userName, props.id]);

  useEffect(() => {

    // Increment views count
    fetch(`https://backend.aptkard.in/aptkard/incrementViewCount?username=${props.userName}`, {
      method: 'PUT'
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to increment views count');
        }

        // Update count state
        setCount(prevCount => prevCount + 1);
      })
      .catch(error => {
        console.error(error);
      });

  }, []);

  const formatFileSize = (fileSize) => {
    if (fileSize < 1024) {
      return `${fileSize} B`;
    } else if (fileSize < 1024 * 1024) {
      const fileSizeInKB = Math.round(fileSize / 1024);
      return `${fileSizeInKB} KB`;
    } else {
      const fileSizeInMB = Math.round(fileSize / (1024 * 1024));
      return `${fileSizeInMB} MB`;
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleGoogleMapsRedirect = (location) => {
    const googleMapsLink = `https://www.google.com/maps/place/${encodeURIComponent(location)}`;
    window.open(googleMapsLink, '_blank');
  };

  const handleSaveContact = (username) => {
    // Prompt the user to enter a file name without .vcf extension
    const suggestedFileName = `${props.userName}`;
    const fileName = window.prompt("Enter a file name :", suggestedFileName);

    if (!fileName) {
      console.log("Download canceled by user.");
      return;
    }

    const encodedFileName = encodeURIComponent(fileName);

    const fetchVCF = fetch(
      `https://backend.aptkard.in/aptkard/contacts.vcf/${username}?fileName=${encodedFileName}`
    );

    fetchVCF
      .then((vcfResponse) => {
        // Handle the VCF response
        vcfResponse.blob().then((blob) => {
          // Append .vcf to the fileName
          const downloadFileName = `${fileName}`;
          const downloadLink = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadLink;
          // Use the user-entered file name with .vcf
          a.download = `${username}.vcf`;
          a.click();
        });
      })
      .catch(() => console.log("Contact save error"));
  };

  return (
    <>
      <div className=" d-flex justify-content-center align-items-center h-100" style={{ maxWidth: '100%' }}>
        <div className="col col-lg-9 col-xl-7" >
          {users.map((user) => {
            if (user.status === 'active') {
              return (
                <div className={`${styles.card} card`} style={{ wimaxWidth: '100%' }}>

                  <div className="  rounded-top text-white d-flex flex-row" style={{
                    backgroundImage: `url(data:image/jpeg;base64,${user.logo})`,
                    height: '200px',
                    width: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}>
                    {user.photo && (
                      <div className="ms-4 d-flex flex-column" style={{ width: '120px', position: 'relative' , top : '120px'}}>
                        <img
                          src={`data:image/jpeg;base64,${user.photo}`}
                          alt="Generic placeholder image"
                          className="img-fluid img-thumbnail "
                          style={{ width: '180px', height: '130px', borderRadius: '100%' }}
                        />


                      </div>
                    )}
                  </div>


                  <div className="p-4 text-black" style={{ backgroundColor: 'black', border: '2px solid #414a4c' }}>
                    <div className="text-white mt-4 text-center"> <h2>{user.fullName}</h2>
                      <p>{user.designation}</p>
                    </div>
                    <div className="d-flex justify-content-end text-center text-white py-1">views:{user.views}</div>
                  </div>

                  <div className="row m-1 d-flex mt-4">
                    <div className="col-4 text-center">
                    
                      <button
                            className={`${styles.sharebutton} btn btn-secondary  btn-sm`}
                            onClick={() => handleSaveContact(user.username)}
                          >
                            Save Contact
                          </button>
                  

                    </div>
                    <div className="col-4 text-center">
                      <button onClick={handleExchangeContactClick} className={`${styles.sharebutton} btn btn-dark`} style={{ width: '100%' }}>
                        <ExchangeContact fullName={user.fullName} email={user.email} />
                      </button>
                    </div>
                    <div className="col-4 text-center">
                      <button className={`${styles.sharebutton} btn btn-dark`} style={{ width: '100%' }} >
                        <User key2={user.username} key3={user.id}>
                        </User>
                      </button>
                    </div>
                  </div>


                  <div className="mx-3 text-white">
                    <div className="row mt-4 text-white">
                      {user.phoneNumber && (
                        <div className="d-flex align-items-center mb-3 text-white">
                          <FaPhone className="me-2" style={{ fontSize: '2rem' }} />
                          <a
                            href={`tel:${user.phoneNumber}`}
                            className="contact-link"
                            style={{ color: 'white', textDecoration: 'none' }}
                          >
                            {user.phoneNumber}
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {user.email && (
                        <div className="d-flex align-items-center mb-3  text-white">
                          <FaEnvelope className="me-2" style={{ fontSize: '2rem' }} />
                          <a
                            href={`mailto:${user.email}`}
                            className="contact-link"
                            rel="noreferrer"
                            target="_blank"
                            style={{ color: 'white', textDecoration: 'none' }}
                          >
                            {user.email}
                          </a>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      {user.location && (
                        <div className="d-flex align-items-center mb-3  text-white">
                          <FaMapMarkerAlt className="me-2" style={{ fontSize: '2rem' }} />
                          <a
                            href={user.location}
                            className="contact-link"
                            rel="noreferrer"
                            target="_blank"
                            style={{ color: 'white', textDecoration: 'none' }}
                          >
                            {user.location}
                          </a>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {user.websiteUrl && (
                        <div className="d-flex align-items-center mb-3  text-white">
                          <FaLink className="me-2" style={{ fontSize: '2rem' }} />
                          <a
                            href={user.websiteUrl}
                            className="contact-link"
                            rel="noreferrer"
                            target="_blank"
                            style={{ color: 'white', textDecoration: 'none' }}
                          >
                            {user.websiteUrl}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>





                  <div className="m-4 d-flex flex-wrap justify-content-center row row-cols-2 row-cols-sm-3 row-cols-md-4">

                    {user.twitterUrl && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 2px rgba(10, 10, 10, 0.25)" }}>
                        <a href={user.twitterUrl} rel="noreferrer" target="_blank">
                          <FaTwitter size={40} style={{ color: "#1da1f2" }} />
                        </a>
                      </div>
                    )}
                    {user.phoneNumber && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={`sms:${user.phoneNumber}`} rel="noreferrer" >
                          <FaComment size={40} style={{ color: "#1da1f2" }} />
                        </a>
                      </div>
                    )}
                    {user.whatsappNumber && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={`whatsapp://send?phone=${user.whatsappNumber}`} rel="noreferrer" target="_blank">
                          <FaWhatsapp size={40} style={{ color: "green" }} />
                        </a>
                      </div>
                    )}
                    {user.instagramUrl && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={user.instagramUrl} rel="noreferrer" target="_blank">
                          <FaInstagram size={40} style={{ color: "#e4405f" }} />
                        </a>
                      </div>
                    )}
                    {user.linkedinUrl && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={user.linkedinUrl} rel="noreferrer" target="_blank">
                          <FaLinkedin size={40} style={{ color: "#0a66c2" }} />
                        </a>
                      </div>
                    )}
                    {user.snapChatUrl && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={user.snapChatUrl} rel="noreferrer" target="_blank">
                          <FaSnapchatGhost size={40} style={{ color: "#fffc00" }} />
                        </a>
                      </div>
                    )}
                    {user.youtubeUrl && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={user.youtubeUrl} rel="noreferrer" target="_blank">
                          <FaYoutube size={40} style={{ color: "#ff0000" }} />
                        </a>
                      </div>
                    )}
                    {user.yahooUrl && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={user.yahooUrl} rel="noreferrer" target="_blank">
                          <FaYahoo size={40} style={{ color: "#720e9e" }} />
                        </a>
                      </div>
                    )}

{user.googleDriveUrl && (
                          <div
                            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
                            style={{
                              boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
                            }}
                          >
                            <a
        href={user.googleDriveUrl}
        rel="noreferrer"
        target="_blank"
        >
    
    <img src="https://upload.wikimedia.org/wikipedia/commons/1/12/Google_Drive_icon_%282020%29.svg"
        alt="Drive"
        style={{
            width: "30px",
            height: "30px",
            
        }}/>
        </a>
                          </div>
                        )}



                    {user.pinterestUrl && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={user.pinterestUrl} rel="noreferrer" target="_blank">
                          <FaPinterest size={40} style={{ color: "#bd081c" }} />
                        </a>
                      </div>
                    )}
                    {user.facebookUrl && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={user.facebookUrl} rel="noreferrer" target="_blank">
                          <FaFacebook size={40} style={{ color: "#3b5998" }} />
                        </a>
                      </div>
                    )}
                    {user.email && (
                      <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                        <a href={`mailto:${user.email}`} rel="noreferrer" target="_blank">
                          <img
                            src="https://www.gstatic.com/images/icons/material/product/2x/gmail_48dp.png"
                            alt="Gmail"
                            style={{
                              width: "40px",
                              height: "40px",
                              color: "#D44638",
                            }}
                          />
                        </a>
                      </div>
                    )}
                  </div>


                  <hr style={{ borderColor: 'white' }} />

                  <ul className={styles.circles}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  { user.aboutUs && (
                  <div className="row">

                    <div>
                      <h3 className="text-center text-white">About Us</h3>
                    </div>
                    <div>
                      {user.aboutUs.split('\n').map((para, index) => (
                        <p className={`${styles.aboutus}`} style={{ marginLeft: '18px', marginRight: '10px' }} key={index}>
                          {para}
                        </p>
                      ))}
                    </div>
                  </div>
          )}
                  <hr />
                  <div>
                    <div className="row text-center  text-white">
                      <h4>QR Code</h4>
                    </div>
                    <div className="row mt-3 ms-4 justify-content-center">
                      <img
                        src={`data:image/jpeg;base64,${user.qrcode}`} alt={'logo'}
                        style={{
                          maxWidth: "90%", marginLeft: "-4px",
                          height: "auto", marginBottom: "20px"
                        }}

                      />
                      <div className=" btn-primary-share text-center">
                        <div className="btn-primary-share">
                          <a
                            href={`data:image/png;base64,${user.qrcode}`}
                            download="qr_code.png"
                            className="btn btn-primary"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                          >
                            {hovered ? (
                              <>
                                <div className="d-flex text-center">
                                  <BsFillArrowDownSquareFill size={20} className="mr-2" />
                                  <span className=" white-text">
                                    {formatFileSize(user.qrcode.length)}
                                  </span>
                                </div>
                              </>
                            ) : (
                              "Download QR Code"
                            )}
                          </a>
                        </div>


                      </div>
                    </div>

                    <hr />
                    <div className="row mt-4 mx-4">

                      <div style={{
                        color: " black"
                      }}>

                        <NewForm key1={user.email} />

                      </div>
                    </div>
                  </div>
                  <ul className={styles.circles}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>

              );
            } else {
              return (
                <div className="childDivTwo container text-center" key={user.id}>
                  <div className="card">
                    <div className="card-body">
                      <h5>This user is inactive.</h5>
                    </div>
                  </div>
                </div>
              );
            }
          })}





        </div>
      </div >
    </>
  );

}
export default Template2;
import React from "react";
import { FaFacebook, FaPhone, FaSave, FaShare, FaExchangeAlt, FaTwitter, FaMapMarkerAlt, FaYahoo, FaLink, FaInstagram, FaSnapchatGhost, FaPinterest, FaLinkedin, FaYoutube, FaEnvelope, FaWhatsapp, FaEye } from 'react-icons/fa';
import { useEffect, useState, useRef } from "react";
import { BsFillArrowDownSquareFill } from "react-icons/bs";
import styles from './Template3.module.css';
import ExchangeContact from "../Components/ExchangeContact";
import User from "../Components/ShareButton";
import NewForm from "../Components/EnquiryForm";

const Template3 = (props) => {

    console.log(props);
    const [count, setCount] = useState(null);
    const [users, setUsers] = useState([]);
    const [hovered, setHovered] = useState(false);
    const [showExchangeContactForm, setShowExchangeContactForm] = useState(false);

    const [show, setShow] = useState(false);

    const toggleAnimation = () => {
      setShow(!show);
    };

    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setIsDarkMode(prevMode => !prevMode);
    };


    const handleExchangeContactClick = () => {
        setShowExchangeContactForm(true);
    };

    const scrollContainerRef = useRef(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft -= 100; // Adjust the scroll amount as needed
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += 100; // Adjust the scroll amount as needed
        }
    };

    
  
    useEffect(() => {
        const fetchData = fetch(
          `https://backend.aptkard.in/aptkard/${props.id}/${props.userName}`
        );
      
        fetchData
          .then((dataResponse) => {
            dataResponse.json().then((data) => {
              setUsers(data);
              console.log(data);
              setCount(data[0].views);
            });
          })
          .catch(() => setUsers("server error/server down"));
      }, [props.userName, props.id]);

    useEffect(() => {

        // Increment views count
        fetch(`https://backend.aptkard.in/aptkard/incrementViewCount?username=${props.userName}`, {
            method: 'PUT'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to increment views count');
                }

                // Update count state
                setCount(prevCount => prevCount + 1);
            })
            .catch(error => {
                console.error(error);
            });

    }, []);

    const formatFileSize = (fileSize) => {
        if (fileSize < 1024) {
            return `${fileSize} B`;
        } else if (fileSize < 1024 * 1024) {
            const fileSizeInKB = Math.round(fileSize / 1024);
            return `${fileSizeInKB} KB`;
        } else {
            const fileSizeInMB = Math.round(fileSize / (1024 * 1024));
            return `${fileSizeInMB} MB`;
        }
    };

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const handleGoogleMapsRedirect = (location) => {
        const googleMapsLink = `https://www.google.com/maps/place/${encodeURIComponent(location)}`;
        window.open(googleMapsLink, '_blank');
    };
    console.log(users)


    const handleSaveContact = (username) => {
        // Prompt the user to enter a file name without .vcf extension
        const suggestedFileName = `${props.userName}`;
        const fileName = window.prompt("Enter a file name :", suggestedFileName);
    
        if (!fileName) {
          console.log("Download canceled by user.");
          return;
        }
    
        const encodedFileName = encodeURIComponent(fileName);
    
        const fetchVCF = fetch(
          `https://backend.aptkard.in/aptkard/contacts.vcf/${username}?fileName=${encodedFileName}`
        );
    
        fetchVCF
          .then((vcfResponse) => {
            // Handle the VCF response
            vcfResponse.blob().then((blob) => {
              // Append .vcf to the fileName
              const downloadFileName = `${fileName}`;
              const downloadLink = URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = downloadLink;
              // Use the user-entered file name with .vcf
              a.download = `${username}.vcf`;
              a.click();
            });
          })
          .catch(() => console.log("Contact save error"));
      };
    return (
        <>
            <div className="card">
                <div className={`${isDarkMode ? '' : 'bg-dark'} container`}>
                    {users.map((user) => {
                        if (user.status === 'active') {
                            return (
                                <div className={`${isDarkMode ? 'bg-light' : 'bg-dark'} card`} style={{ border: 'none' }}>
                                    <div className={`${isDarkMode ? '' : 'dark-mode'} text-end`} >
                                        <div className={`box ${show ? 'fade-in' : ''}`} onClick={toggleAnimation}>
                                            <button onClick={toggleDarkMode} className={`${styles.dark_mode_toggle}`} >
                                                {isDarkMode ? (
                                                    <i>☀️</i>
                                                ) : (
                                                    <i className="fas fa-moon" style={{ color: 'white' }}></i>
                                                )}
                                            </button>
                                       </div>
                                    </div>      

                                
                                    <div className="row">
                                        <div className="col-6 d-flex align-items-center justify-content-center">
                                        <div style={{ position: 'relative', width: '180px', height: '180px' }}>

    <img
        src={`data:image/jpeg;base64,${user.photo || ''}`}
      
        className="img-fluid img-thumbnail m-2"
        style={{ width: '155px', height: '155px', borderRadius: '50%' }}
    />
    
    { user.logo && (
        <img
            src={`data:image/jpeg;base64,${user.logo}`}
            alt="User's logo"
            className="img-fluid img-thumbnail"
            style={{
                width: '70px',
                height: '70px',
                borderRadius: '50%',
                position: 'absolute',
                bottom: '0',
                right: '0',
            }}
        />
    )}
</div>

                                    </div>


                                    <div className={`${isDarkMode ? '' : 'text-white'} col-6 d-flex text-center align-items-center justify-content-center`}>
                                        <div>
                                            <div className="row">
                                                    <h2>{user.fullName}</h2>
                                            </div>
                                            <div className="row">
                                                    <p>{user.designation}</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${isDarkMode ? '' : 'text-white'} row mt-3 `}>
                                        <div className="col-6 text-center">
                                            {user.phoneNumber && (
                                                <div>
                                                    <FaPhone className="me-2" style={{ fontSize: '1rem' }} />
                                                    <a
                                                        href={`tel:${user.phoneNumber}`}
                                                        className="contact-link"
                                                        style={{ color: isDarkMode ? 'black' : 'white', textDecoration: isDarkMode ? 'none' : 'none', }}
                                                    >
                                                        {user.phoneNumber}
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            <div className=" justify-content-center text-center "><FaEye className="me-2" style={{ fontSize: '1rem' }} />views:{user.views}</div>
                                        </div>
                                    </div>

                                    <div className="row d-flex mt-4">
                                        <div className="col-4  d-flex justify-content-center" >
                               
                      <button
                         className={`${styles.sharebutton} ${isDarkMode ? 'btn-outline-dark' : 'btn-outline-light'} btn btn-sm`}
                            onClick={() => handleSaveContact(user.username)}
                          >
                            Save Contact
                          </button>
                                        </div>
                                        <div className="col-4 d-flex justify-content-center"> {/* Right align the content */}
                                            <button onClick={handleExchangeContactClick} className={`${styles.sharebutton} ${isDarkMode ? 'btn-outline-dark' : 'btn-outline-light'} btn btn-sm`}>
                                                <FaExchangeAlt />  <ExchangeContact fullName={user.fullName} email={user.email} ></ExchangeContact>
                                            </button>
                                        </div>
                                        <div className="col-4 d-flex justify-content-center" > {/* Center align the content */}

                                            <button className={`${styles.sharebutton} ${isDarkMode ? 'btn-outline-dark' : 'btn-outline-light'} btn  btn-sm`}>
                                                <User key2={user.username} key3={user.id}><FaShare /></User>
                                            </button>
                                        </div>
                                    </div>

                                    <div className={`${isDarkMode ? 'bg-dark' : ''} card mt-3`} style={{ borderRadius: '20px  20px  10px 10px', border: isDarkMode ? 'none' : 'none' }} >
                                        <div className="m-4 d-flex flex-nowrap  overflow-auto row row-cols-2 row-cols-sm-3 row-cols-md-4" ref={scrollContainerRef}>
                                            <div className={`${styles.scroll_arrow} ${styles.right_arrow} `} onClick={scrollRight}>
                                                <span className="fw-bold">&#8250;</span>
                                            </div>

                                            {user.twitterUrl && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 2px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={user.twitterUrl} rel="noreferrer" target="_blank">
                                                        <FaTwitter size={30} style={{ color: "#1da1f2" }} />
                                                    </a>
                                                </div>
                                            )}
                                            {user.whatsappNumber && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={`whatsapp://send?phone=${user.whatsappNumber}`} rel="noreferrer" target="_blank">
                                                        <FaWhatsapp size={30} style={{ color: "green" }} />
                                                    </a>
                                                </div>
                                            )}
                                            {user.instagramUrl && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={user.instagramUrl} rel="noreferrer" target="_blank">
                                                        <FaInstagram size={30} style={{ color: "#e4405f" }} />
                                                    </a>
                                                </div>
                                            )}
                                            {user.linkedinUrl && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={user.linkedinUrl} rel="noreferrer" target="_blank">
                                                        <FaLinkedin size={30} style={{ color: "#0a66c2" }} />
                                                    </a>
                                                </div>
                                            )}
                                            {user.snapChatUrl && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={user.snapChatUrl} rel="noreferrer" target="_blank">
                                                        <FaSnapchatGhost size={30} style={{ color: "#fffc00" }} />
                                                    </a>
                                                </div>
                                            )}
                                            {user.youtubeUrl && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={user.youtubeUrl} rel="noreferrer" target="_blank">
                                                        <FaYoutube size={30} style={{ color: "#ff0000" }} />
                                                    </a>
                                                </div>
                                            )}
                                            {user.yahooUrl && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={user.yahooUrl} rel="noreferrer" target="_blank">
                                                        <FaYahoo size={30} style={{ color: "#720e9e" }} />
                                                    </a>
                                                </div>
                                            )}
                                                {user.googleDriveUrl && (
            <div
            className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`}
            style={{
                boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)",
            }}>
        <a
        href={user.googleDriveUrl}
        rel="noreferrer"
        target="_blank"
        >
    
    <img src="https://upload.wikimedia.org/wikipedia/commons/1/12/Google_Drive_icon_%282020%29.svg"
        alt="Drive"
        style={{
            width: "30px",
            height: "30px",
            
        }}/>
        </a>
        </div>
        )}
                                            {user.pinterestUrl && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={user.pinterestUrl} rel="noreferrer" target="_blank">
                                                        <FaPinterest size={30} style={{ color: "#bd081c" }} />
                                                    </a>
                                                </div>
                                            )}
                                            {user.facebookUrl && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={user.facebookUrl} rel="noreferrer" target="_blank">
                                                        <FaFacebook size={30} style={{ color: "#3b5998" }} />
                                                    </a>
                                                </div>
                                            )}
                                            {user.email && (
                                                <div className={`${styles.Iconcard} ${styles.Iconcard2} card border-0 m-2`} style={{ boxShadow: "0px 2px 4px rgba(10, 10, 10, 0.25)" }}>
                                                    <a href={`mailto:${user.email}`} rel="noreferrer" target="_blank">
                                                        <img
                                                            src="https://www.gstatic.com/images/icons/material/product/2x/gmail_48dp.png"
                                                            alt="Gmail"
                                                            style={{
                                                                width: "30px",
                                                                height: "30px",
                                                                color: "#D44638",
                                                            }}
                                                        />
                                                    </a>
                                                </div>
                                            )}

                                            <div className={`${styles.scroll_arrow} ${styles.left_arrow}`} onClick={scrollLeft}>
                                                <span>&#8249;</span>
                                            </div>
                                        </div>
                                        <div className={`${isDarkMode ? 'bg-dark' : ''} card`} style={{ borderRadius: '20px 20px 20px 20px ', border: isDarkMode ? 'none' : 'none' }}>
                                            <div className={`${isDarkMode ? 'text-white' : ''} mx-3 mt-3`}>

                                                <div className="row">
                                                    {user.email && (
                                                        <div className={`${isDarkMode ? 'text-white' : ''} d-flex align-items-center mb-3 `}>
                                                            <FaEnvelope className="me-3" style={{ fontSize: '1rem' }} />
                                                            <a
                                                                href={`mailto:${user.email}`}
                                                                className="contact-link"
                                                                rel="noreferrer"
                                                                target="_blank"
                                                                style={{ color: isDarkMode ? 'white' : 'black', textDecoration: isDarkMode ? 'none' : 'none' }}
                                                            >
                                                                {user.email}
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="row">
                                                    {user.location && (
                                                        <div className={`${isDarkMode ? 'text-white' : 'text-black'} d-flex align-items-center mb-3 `}>
                                                            <FaMapMarkerAlt className="me-3" style={{ fontSize: '1rem' }} />
                                                            <a
                                                                href={user.location}
                                                                className="contact-link"
                                                                rel="noreferrer"
                                                                target="_blank"
                                                                style={{ color: isDarkMode ? 'white' : 'black', textDecoration: isDarkMode ? 'none' : 'none' }}
                                                            >
                                                                {user.location}
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="row">
                                                    {user.websiteUrl && (
                                                        <div className={`${isDarkMode ? 'text-white' : 'text-black'} d-flex align-items-center mb-3 `}>
                                                            <FaLink className="me-3" style={{ fontSize: '1rem' }} />
                                                            <a
                                                                href={user.websiteUrl}
                                                                className="contact-link"
                                                                rel="noreferrer"
                                                                target="_blank"
                                                                style={{ color: isDarkMode ? 'white' : 'black', textDecoration: isDarkMode ? 'none' : 'none', }}
                                                            >
                                                                {user.websiteUrl}
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={`${isDarkMode ? 'bg-dark' : ''} card`} style={{ borderRadius: '10px  10px  10px 10px', border: isDarkMode ? '1px solid white' : '' }}>

                                        <div className={`${isDarkMode ? 'text-white' : ''} mt-5 mb-2 d-flex justify-content-start `}  >
                                            <h4 className="ms-4 fw-bold ">About Us</h4></div>

                                        <div>
                                            {user.aboutUs.split('\n').map((para, index) => (
                                                <p className={`${styles.aboutus} ${isDarkMode ? 'text-white' : ''}`} style={{ marginLeft: '18px' }} key={index}>
                                                    {para}
                                                </p>
                                            ))}
                                        </div>

                                        <hr className={`${isDarkMode ? 'text-white' : ''}`} ></hr>
                                        <div className="row">
                                            <div className="text-center">
                                                <h4 className="fw-bold">QR CODE</h4>
                                            </div>

                                            <div className="row mt-3 justify-content-center">
                                                <img
                                                    src={`data:image/jpeg;base64,${user.qrcode}`} alt={'logo'}
                                                    style={{
                                                        maxWidth: "90%",
                                                        height: "auto", marginBottom: "20px"
                                                    }}

                                                />
                                                <div className=" btn-primary-share text-center">
                                                    <div className="btn-primary-share">
                                                        <a
                                                            href={`data:image/png;base64,${user.qrcode}`}
                                                            download="qr_code.png"
                                                            className="btn btn-primary"
                                                            onMouseEnter={handleMouseEnter}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            {hovered ? (
                                                                <>
                                                                    <div className="d-flex text-center">
                                                                        <BsFillArrowDownSquareFill size={20} className="mr-2" />
                                                                        <span className=" white-text">
                                                                            {formatFileSize(user.qrcode.length)}
                                                                        </span>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                "Download QR Code"
                                                            )}
                                                        </a>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        <hr className={`${isDarkMode ? 'text-white' : ''}`} />

                                        <div className="row mt-4 mx-4  text-black">

                                            <div style={{
                                                color: "black"
                                                
                                            }}>

                                                <NewForm key1={user.email} />

                                            </div>
                                        </div>

                                    </div>


                                </div>
                            )
                        }
                    })
                    }
                </div>
            </div>
        </>
    )
}

export default Template3;
import Swal from 'sweetalert2';
import styles from "./Enrollment.module.css";
import axios from 'axios';
import React, { useState, useEffect } from "react";

const API_URL = 'https://backend.aptkard.in/api/posts';
const defaultImageUrl = 'path_to_default_image.jpg'; // Replace with the actual URL of your default image


const Enrollment = () => {
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    getAllPublicPosts();
  }, []);


  const handleNameChange = (e) => {
    // Validation logic
    const inputValue = e.target.value;

    if (/[^a-zA-Z ]/.test(inputValue)) {
      setNameError('Please enter text only');
    } else {
      setNameError('');
    }

    // Update form data
    setFormData({ ...formData, name: inputValue });
  };

  const handleEmailChange = (e) => {
    // Validation logic
    const inputValue = e.target.value;

    if (!/^[a-zA-Z0-9._%+-]+@gmail\.com$/.test(inputValue)) {
      setEmailError('Please enter a valid Gmail address (e.g., example@gmail.com)');
    } else {
      setEmailError('');
    }

    // Update form data
    setFormData({ ...formData, email: inputValue });
  };

  const handleMobileNumberChange = (e) => {
    // Validation logic
    const inputValue = e.target.value;

    if (!/^[6789]\d{0,9}$/.test(inputValue)) {
      setMobileNumberError('Please enter a valid 10-digit mobile number starting with 6, 7, 8, or 9.');
    } else {
      setMobileNumberError('');
    }

    // Update form data
    if (inputValue.length <= 10) {
      setFormData({ ...formData, mobileNumber: inputValue });
    }
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobileNumber: '',
    noOfCards: '',
    typeOfCard: '',
    location: '',
    note: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for form data validity before submitting
    if (!formData.name || !formData.email || !formData.mobileNumber) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill in all required fields',
        confirmButtonText: 'OK'
      });
      return;
    }

    try {
      await axios.post('https://backend.aptkard.in/api/saved', formData);
      setFormData({
        name: '',
        email: '',
        mobileNumber: '',
        noOfCards: '',
        typeOfCard: '',
        location: '',
        note: ''
      });

      // Display success message
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Application submitted successfully. We will get back to you soon.',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error('Error submitting application:', error);

      // Display error message
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error submitting application',
        confirmButtonText: 'OK'
      });
    }
  };



  const getAllPublicPosts = async () => {
    try {
      const response = await axios.get(`${API_URL}/getAllPosts`);
      setPosts(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const getPostById = async (id) => {
    try {
      const response = await axios.get(`${API_URL}/${id}`);
      setSelectedPost(response.data);
    } catch (error) {
      console.error('Error fetching post by ID:', error);
    }
  };

  return (
    <div className='container-fluid bg-dark  ' style={{ maxWidth: '100%' }}>


      <img src='\assests\22.png' width='10%' height='30%' className='mt-3' style={{ marginLeft: '30px' }} />

      <div className='row mt-4 text-center'>
        <h2 className='text-white' style={{ fontSize: '50px' }}>We <spam style={{ color: 'grey' }}>build </spam>different <br />experiences.</h2>
        <p className='text-white' style={{ fontSize: '35px' }}>We are commited to being here for you for the   long term,<br></br>providing support and assistance whenever you need.</p>
        <div className='mb-5'>
          <a href='https://aptweb.aptkard.in/'> <button type='button' className='btn  btn-lg btn-outline-light' style={{ borderRadius: '30px' }}>
            More Info  <i class="fa-solid fa-circle-info">
            </i>
          </button></a>
        </div>
      </div>


      <div className='row mt-5 mb-3'>
        <div className='col-md-6 text-center mb-3'>
          <img src='\assests\aptkard.png' width='70%' height='80%' alt='APT Kard' />
        </div>
        <div className='col-md-6'>
          <h5 className='text-white' style={{ fontWeight: 'bold' }}>OUR SERVICES</h5>
          <h1 className='text-white' style={{ color: 'grey' }}>NFC Business Card Services</h1>
          <p style={{ fontSize: '25px', color: 'grey' }}>Revolutionize your business with NFC technology.</p>
          <p style={{ fontSize: '25px', color: 'grey' }}>Take your business to the next level with our NFC solutions and cutting-edge website developing strategies.</p>
          <p style={{ fontSize: '25px', color: 'grey' }}>Streamline transactions and increase customer engagement with our NFC solutions.</p>

          <div className='mb-5'>
            <button className={`${styles.btn} btn btn-lg btn-outline-light`} style={{ borderRadius: '30px' }}>
              Get Started
            </button>
          </div>
          <div className="text-end" style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1' }}>
            <a href='https://wa.me/8712655800'>
              <button className="btn btn-lg btn-success btn-outline-light" style={{ borderRadius: '30px' }}>
                <i className="fab fa-whatsapp fa-xl mx-3"></i> How Can I Help You?
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className='container mt-5'>
        <div className='row'>
          <div className='col-lg-6  text-center'>
            <iframe
              title="Embedded Content"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d201.65652990547022!2d78.44759304545711!3d17.44136551189613!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91511792d167%3A0xd78708792b14ecdc!2sAPT%20IT%20Solutions!5e1!3m2!1sen!2sin!4v1700037431373!5m2!1sen!2sin"
              referrerpolicy="no-referrer-when-downgrade"
              style={{
                width: '100%',
                height: '545px',
                border: 'none'
              }}
            ></iframe>

          </div>

          <div className='col-lg-6 text-start' style={{ backgroundColor: 'dark' }}>
            <div className='container'>
              <div className='row'>
                <div className='card' style={{ backgroundColor: '#939597' }}>
                  <div className='card-form-container'>
                    <h3 className='mt-2'>Enquiry Form</h3>
                    <p className='text-black'>Please fill out the form to know the card details and pricing</p>
                    <form onSubmit={handleSubmit}>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='name'>Name:</label>
                            <input
                              type='text'
                              name='name'
                              id='name'
                              value={formData.name}
                              onChange={handleNameChange}
                              className='form-control'
                            />
                            {nameError && <div className='error-message'>{nameError}</div>}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='email'>Email:</label>
                            <input
                              type='email'
                              name='email'
                              id='email'
                              value={formData.email}
                              onChange={handleEmailChange}
                              className='form-control'
                            />
                          </div>
                          {emailError && <div className='error-message'>{emailError}</div>}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='mobileNumber'>Mobile Number:</label>
                            <input
                              type='text'
                              name='mobileNumber'
                              id='mobileNumber'
                              value={formData.mobileNumber}
                              onChange={handleMobileNumberChange}
                              className='form-control'
                            />
                            {mobileNumberError && <div className='error-message'>{mobileNumberError}</div>}
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='noOfCards'>Number of Cards:</label>
                            <input
                              type='number'
                              name='noOfCards'
                              id='noOfCards'
                              value={formData.noOfCards}
                              onChange={(e) => setFormData({ ...formData, noOfCards: e.target.value })}
                              className='form-control'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='typeOfCard'>Types of Cards:</label>
                            <select
                              name='typeOfCard'
                              id='typeOfCard'
                              value={formData.typeOfCard}
                              onChange={(e) => setFormData({ ...formData, typeOfCard: e.target.value })}
                              className='form-control'
                            >
                              <option>select one of these</option>
                              <option value='pvc'>PVC</option>
                              <option value='metal cards'>Metal Cards</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='form-group'>
                            <label htmlFor='location'>Location:</label>
                            <input
                              type='text'
                              name='location'
                              id='location'
                              value={formData.location}
                              onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                              className='form-control'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-group'>
                        <label htmlFor='note'>Note:</label>
                        <textarea
                          name='note'
                          id='note'
                          value={formData.note}
                          onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                          className='form-control'
                          rows='4'
                        />
                      </div>
                      <div className='buttons mb-3'>
                        <button type='submit' className='btn btn -lg btn-primary'>
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <br></br>

      <footer className='mt-5 '>
        <div className='justify-content-center d-flex'>
          <img src='\assests\22.png' width='10%' height='30%' />
        </div>

        <div className='row text-center' style={{ padding: '30px' }}>
          <p className='mt-3 mb-3 text-center text-white'> Proudly Powered By <a href='https://aptits.com/'>APT IT SOLUTIONS</a></p>

          <div className='iconcnt col-sm-12  justify-content-center align-items-center'>

            <a href='https://www.facebook.com/'>
              <i className="fab fa-facebook fa-2x mx-3" style={{ color: "#1877f2" }}></i>
            </a>

            <a href='https://web.whatsapp.com/'>
              <i className="fab fa-whatsapp fa-2x mx-3" style={{ color: "#25d366" }}></i>
            </a>

            <a href='https://twitter.com/'>
              <i className="fab fa-twitter fa-2x mx-3" style={{ color: "#1da1f2" }}></i>
            </a>

            <a href='https://www.instagram.com/'>
              <i className="fab fa-instagram fa-2x mx-3" style={{ color: "#e4405f" }}></i>
            </a>

            <a href='https://www.youtube.com/results?search_query=aptitsolutions'>
              <i className="fab fa-youtube fa-2x mx-3" style={{ color: "#ff0000" }}></i>
            </a>

            <a href='https://www.linkedin.com/company/apt-it-solutions/'>
              <i className="fab fa-linkedin fa-2x mx-3" style={{ color: "#0077b5" }}></i>
            </a>


            <a href=''>
              <i className="far fa-envelope fa-2x mx-3" style={{ color: 'rosybrown' }}></i>
            </a>

          </div>

          <h5 className='text-white mt-3 text-center'>  3rd Floor, Sahithi Arcade, Police Station, near S R Nagar, Srinivasa Nagar, Sanjeeva Reddy Nagar, Hyderabad,India.Email:info@aptits.com</h5>

          <a href=''> <h3 className='text-white text-center '>Terms & Conditions Applied</h3></a>
        </div>
      </footer>





    </div>
  );
};

export default Enrollment;
import React, { useState } from 'react';

import Swal from 'sweetalert2';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';

const Registration = () => {
  const [fullName, setFullName] = useState('');
  const [username, setUserName] = useState('');
  const [designation, setDesignation] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [location, setLocation] = useState('');
  const [email, setEmail] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [snapChatUrl, setSnapChatUrl] = useState('');
  const [pinterestUrl, setPinterestUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [yahooUrl, setYahooUrl] = useState('');
  const [googleDriveUrl,setGoogleDriveUrl] =useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [templateStyle, setTemplateStyle] = useState('');
  const [logo, setLogo] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [aboutUs, setAboutUs] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Initial loading state

  const [isBuffering, setIsBuffering] = useState(false);

  const navigate = useNavigate("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsBuffering(true);

    // Retrieve the user ID from localStorage
    const userId = localStorage.getItem('userId');


    // Check if required fields are filled
    if (!username || !fullName || !templateStyle || !designation || !email || !phoneNumber || !whatsappNumber) {
      // Display an error message
      setIsBuffering(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill  all * required fields..................................... user name, full name, template style, designation, email, phone number, whatsapp number'
      });
      return;
    }

    // Create a new FormData object
    const formData = new FormData();

    // Append all fields to the FormData object
    formData.append('fullName', fullName);
    formData.append('username', username);
    formData.append('designation', designation);
    formData.append('phoneNumber', phoneNumber);
    formData.append('whatsappNumber', whatsappNumber);
    formData.append('location', location);
    formData.append('email', email);
    formData.append('websiteUrl', websiteUrl);
    formData.append('instagramUrl', instagramUrl);
    formData.append('facebookUrl', facebookUrl);
    formData.append('snapChatUrl', snapChatUrl);
    formData.append('pinterestUrl', pinterestUrl);
    formData.append('linkedinUrl', linkedinUrl);
    formData.append('youtubeUrl', youtubeUrl);
    formData.append('twitterUrl', twitterUrl);
    formData.append('yahooUrl', yahooUrl);
    formData.append('googleDriveUrl',googleDriveUrl);
    formData.append('createdBy', "kuladeep");
    formData.append('templateStyle', templateStyle);
    formData.append('file', logo);
    formData.append('photo', photo);
    formData.append('aboutUs', aboutUs);

    try {
      // Send the POST request with the FormData object as the body
      await axios.post('https://backend.aptkard.in/aptkard/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json,text/*;q=0.99',
        },
      });

      // Handle success case
      console.log('Registration successful!');
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Data saved successfully.',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/viewpanel'); // Navigate to '/viewpanel' page
          // Clear input field values
          setFullName('');
          setUserName('');
          setDesignation('');
          setPhoneNumber('');
          setWhatsappNumber('');
          setLocation('');
          setEmail('');
          setWebsiteUrl('');
          setInstagramUrl('');
          setFacebookUrl('');
          setSnapChatUrl('');
          setPinterestUrl('');
          setLinkedinUrl('');
          setYoutubeUrl('');
          setTwitterUrl('');
          setYahooUrl('');
          setGoogleDriveUrl('');
          setCreatedBy('');
          setTemplateStyle('');
          setLogo(null);
          setPhoto(null);
          setAboutUs('');
        }
      });
    } catch (error) {
      // Handle error case
      console.error('Error occurred during registration:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to submit the data. Please try again later.',
      });
    } finally {
      // Reset the loading state
      setIsLoading(false);
      setIsBuffering(false);
    }

  };

  const func_five = () => {
    navigate('/home');
  }


  return (
    <>
          <div className='container' style={{marginTop : '5px'}}>
            <div className='card card-light text-dark' style={{ backgroundColor: 'lightgrey' }}>
              <center><h3>REGISTRATION</h3></center>
              <div className='card-body' >
                <div className='card card-light text-body'>
                  <div className='card-body'>
                    <h6 className='card-title'>
                      <span style={{ color: 'red' }}>*</span> Mandatory Fields
                    </h6>
                    <div className='row m-2'>
                      <div className='col-sm'>
                        <div className='mb-1'>
                          <label htmlFor='username'>Username:<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type='text'
                            id='username'
                            className='form-control form-control-sm'
                            value={username}
                            onChange={(e) => setUserName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-lg'>
                        <div className='mb-1'>
                          <label htmlFor='fullName'>Full Name:<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type='text'
                            id='fullName'
                            className='form-control form-control-sm'
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-lg'>
                        <div className='mb-1'>
                          <label htmlFor='email'>Email:<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type='text'
                            id='email'
                            className='form-control form-control-sm'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row m-1'>
                      <div className='col-sm'>
                        <label htmlFor='phoneNumber'>Phone Number:<span style={{ color: 'red' }}>*</span></label>
                        <input
                          type="text"
                          id="phoneNumber"
                          className="form-control form-control-sm"
                          style={{ width: '300px' }}
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                      <div className='col-sm'>
                        <label htmlFor='whatsappNumber'>WhatsApp Number:<span style={{ color: 'red' }}>*</span></label>
                        <PhoneInput
                          country=''
                          value={whatsappNumber}
                          onChange={setWhatsappNumber}
                          inputProps={{
                            type: 'text',
                            id: 'whatsappNumber',
                            className: 'form-control form-control-md',
                            placeholder: 'Please enter a valid WhatsApp number',
                          }}
                        />
                      </div>
                      <div className='col-sm'>
                        <div>
                          <label htmlFor='designation'>Designation:<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type='text'
                            id='designation'
                            className='form-control form-control-md'
                            value={designation}
                            onChange={(e) => setDesignation(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-sm-4'>
                          <label htmlFor="templateStyle">Template Style: <span style={{ color: 'red' }}>*</span></label>
                          <select
                            id="templateStyle"
                            className="form-control form-control-sm"
                            style={{ width: '300px' }}
                            value={templateStyle}
                            onChange={(e) => setTemplateStyle(e.target.value)}
                          >
                            <option value="">select template</option>
                            <option value="Template1">template 1</option>
                            <option value="Template2">template 2</option>
                            <option value="Template3">template 3</option>
                            <option value="Template4">template 4</option>
                            <option value="Template5"> info@vishnu.edu.in </option>
                          </select>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="row m-0 mx-3">
                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="websiteUrl">Website URL:</label>
                      <input
                        type="text"
                        id="websiteUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={websiteUrl}
                        onChange={(e) => setWebsiteUrl(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="instagramUrl">Instagram URL:</label>
                      <input
                        type="text"
                        id="instagramUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={instagramUrl}
                        onChange={(e) => setInstagramUrl(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="facebookUrl">Facebook URL:</label>
                      <input
                        type="text"
                        id="facebookUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={facebookUrl}
                        onChange={(e) => setFacebookUrl(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0 mx-3">
                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="snapChatUrl">Snapchat URL:</label>
                      <input
                        type="text"
                        id="snapChatUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={snapChatUrl}
                        onChange={(e) => setSnapChatUrl(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="pinterestUrl">Pinterest URL:</label>
                      <input
                        type="text"
                        id="pinterestUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={pinterestUrl}
                        onChange={(e) => setPinterestUrl(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="linkedinUrl">LinkedIn URL:</label>
                      <input
                        type="text"
                        id="linkedinUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={linkedinUrl}
                        onChange={(e) => setLinkedinUrl(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0 mx-3">
                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="youtubeUrl">YouTube URL:</label>
                      <input
                        type="text"
                        id="youtubeUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={youtubeUrl}
                        onChange={(e) => setYoutubeUrl(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="twitterUrl">Twitter URL:</label>
                      <input
                        type="text"
                        id="twitterUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={twitterUrl}
                        onChange={(e) => setTwitterUrl(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="yahooUrl">Yahoo URL:</label>
                      <input
                        type="text"
                        id="yahooUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={yahooUrl}
                        onChange={(e) => setYahooUrl(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col">
                    <div className="mb-1">
                      <label htmlFor="googleDriveUrl">Google Drive URL:</label>
                      <input
                        type="text"
                        id="googleDriveUrl"
                        className="form-control form-control-sm"
                        style={{ width: '300px' }}
                        value={googleDriveUrl}
                        onChange={(e) => setGoogleDriveUrl(e.target.value)}
                      />
                    </div>
                  </div>

                </div>
                <div className='container'>
                  <div className='row mx-1'>
                    <div className='col-sm-12'>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <label htmlFor="logo">Logo:</label>
                          <input
                            type="file"
                            id="logo"
                            className="form-control form-control-sm"
                            style={{ width: '300px' }}
                            onChange={(e) => setLogo(e.target.files[0])}
                          />
                        </div>
                        <div className='col-sm-4'>
                          <label htmlFor="photo">Photo:</label>
                          <input
                            type="file"
                            id="photo"
                            className="form-control form-control-sm"
                            style={{ width: '300px' }}
                            onChange={(e) => setPhoto(e.target.files[0])}
                          />
                        </div>
                    
                      </div>
                      <div className='row mt-1'>
                        <div className='col-sm-4'>
                          <label htmlFor="aboutUs">About Us:</label>
                          <textarea
                            id="aboutUs"
                            className="form-control form-control-sm"
                            value={aboutUs}
                            style={{ width: '300px' }}
                            onChange={(e) => setAboutUs(e.target.value)}
                          />
                        </div>
                        <div className='col-sm-4'>
                          <label htmlFor="location">Location:</label>
                          <textarea
                            id="location"
                            className="form-control form-control-sm"
                            value={location}
                            style={{ width: '300px' }}
                            onChange={(e) => setLocation(e.target.value)}
                          />
                        </div>
                        <div className='col-sm-4 d-flex justity-content-center align-items-center' >
                          <div>
                            <button
                              className="btn btn-secondary btn-sm"
                              onClick={handleSubmit}
                              disabled={isBuffering}

                            >
                                {isBuffering ? 'Buffering...' : 'Submit'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  );
};

export default Registration;
import React, { useState } from 'react';
import styles from './UserLogin.module.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../Context/UserContext';

const UserLogin = ({setIsUserLogin}) => {
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const { setUserData } = useUserContext(); // Get the setUserData function from the context

    const navigate = useNavigate('');
    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoadingMore(true);
        if (username.trim() === '' && password.trim() === '') {   
            setIsLoadingMore(false); 
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fill  all * required fields',
        });       
        } else {
            setIsLoadingMore(true);

            const formData = new FormData();
            formData.append('username',username);
            formData.append('password',password);
            console.log(username ,password);
            try{ 
                await axios.post('https://backend.aptkard.in/aptkard/user/login', formData).then(
                (response) => {
                 
                let { data } = response;
                let { message, userId, username } = data;
                setUserData({ userId, username }); // Set user data in the context

                if(message === 'Login successful') {
                setIsLoadingMore(false);
                setUserName('');
                setPassword('');
                setIsUserLogin(true);
                navigate('/ChooseTemplateScreen'); // Navigate to '/viewpanel' page
                } else {
                    setIsUserLogin(false);
                    Swal.fire({
                        icon: 'warning',
                        title: 'Error',
                        text: 'please enter the valid USERNAME and PASSWORD.',
                    }); 
                    setIsLoadingMore(false);
                }
                }
                )
            } catch (error) {
                // Handle error case
                console.log(formData.values);
                console.error('Error occurred during registration:', error);
                setIsLoadingMore(false);
                setIsUserLogin(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to submit the data. Please try again later.',
                });
            }
        }
      };

    return (
        <div className={`d-flex justify-content-center align-items-center ${styles.container}`}>
            <div className={`card ${styles.card}`}>
                <div className="d-flex justify-content-center mb-0 m-0">
                    <a href="https://www.aptits.com/Home" rel="noreferrer" target='_blank'>
                    <img src="../assests/APTLOGObg.png"
                        alt="logo APT IT"
                        className={`${styles.img} mb-0 m-0`}/>
                    </a>
                </div>
                <p className={`${styles.heading} text-center`}>USER LOGIN</p>
                <form className="m-4">
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">
                            User Name:
                        </label>
                        <input
                            type="text"
                            id="username"
                            className="form-control"
                            value={username}
                            onChange={(event) => setUserName(event.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                            Password:
                        </label>
                        <input
                            type="password"
                            id="password"
                            className="form-control"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                        />
                    </div>
                    <div>
                       <button className='btn btn-outline-default' onClick={handleLogin}> { isLoadingMore ? "Loading...." : "LOGIN" }</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserLogin;
import React, { useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { FaBars, FaUserFriends, FaPencilAlt, FaEye } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import Header from '../Components/Header';
import Registration from './Registration';
import ViewPanel from './ViewPanel';
import Home from '../Components/Home';
import UserEnrollment from './UserEnrollment';
import ListUserEnrollment from './ListUserEnrollment';

const SideNav = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const menuItem = [
    {
      path: '/viewpanel',
      name: 'ViewPanel',
      icon: <FaUserFriends />,
    },
    {
      path: '/registration',
      name: 'Registration',
      icon: <FaPencilAlt />,
    },
    {
      path: '/listuserenrollment',
      name: 'ListuserEnrollment',
      icon: <FaEye />,
    },

  ];

  return (
    <>


      <Header />
      <div className="d-flex" style={{ backgroundColor: 'white', marginTop: '56px' }}>
        <div className={`sidebar ${isOpen ? 'collapsed' : 'collapsed'}`} style={{ backgroundColor: 'black',  height: '100vh', position: 'fixed' }}>
          <div className="top_section">

            <div style={{ marginLeft: isOpen ? '5px' : '5px' }} className="bars">
              <FaBars onClick={toggle} size={50} style={{ margin: '10px', alignItems: 'center', color: 'whitesmoke' }} />
            </div>
          </div>
          <ul className="menu_items" style={{ listStyleType: 'none', padding: 0 }}>
            {menuItem.map((item, index) => (
              <li key={index} className="menu_item">
                <NavLink
                  to={item.path}
                  className="link"

                  style={{ textDecoration: 'none', color: 'whitesmoke' }}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon" style={{ margin: '0 10px', fontSize: '50px', color: 'whitesmoke' }}>
                      {item.icon}
                    </div>
                    <div style={{ display: isOpen ? 'block' : 'none' }} className="link_text">
                      {item.name}
                    </div>
                  </div>
                </NavLink>
              </li>
            ))}
          </ul>

        </div>

      </div>

      <Routes>
        <Route path="/registration" element={<Registration />} />
        <Route path="/viewpanel" element={<ViewPanel />} />
        <Route path="/home" element={<Home />} />
        <Route path="/userenrollment" element={<UserEnrollment />} />
        <Route path="/listuserenrollment" element={<ListUserEnrollment />} />
      </Routes>

    </>
  );
};

export default SideNav;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaUserFriends, FaPencilAlt } from 'react-icons/fa';
import { Button, Card } from 'react-bootstrap';
import './Header.css';
import Swal from 'sweetalert2';

const Header = () => {
  const navigate = useNavigate();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const handleBackToHome = () => {
    navigate('/home');
    setIsButtonClicked(true);
  };

  const handleLogout = () => {
    Swal.fire({
      title: 'Logout Confirmation',
      text: 'Are you sure you want to log out?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoggedOut(true);
        navigate('/admin', { replace: true });
      }
    });
  };

  useEffect(() => {
    if (isLoggedOut) {
      navigate('/admin', { replace: true });
    }
  }, [isLoggedOut, navigate]);

  const handleResponsiveness = () => {
    if (window.innerWidth <= 768) {
      document.querySelector('.navbar').classList.add('header-responsive');
      document.querySelector('.navbar').style.width = '100%';
    } else {
      document.querySelector('.navbar').classList.remove('header-responsive');
    }
  };

  useEffect(() => {
    handleResponsiveness();
    window.addEventListener('resize', handleResponsiveness);
    return () => window.removeEventListener('resize', handleResponsiveness);
  }, []);

  return (
<header
  className="navbar navbar-default header-responsive"
  style={{
    backgroundColor: 'black',
    width: '100%',
    position:'fixed',

    top: 0
  }}
>
      <div className="navbar-header mx-1">   
            <img src="../assests/APTLOGO.png" alt="Logo" style={{width: '65px', alignItems: 'center' }} />
      </div>
      <h3 style={{ color: 'orange', marginLeft: '50px' }}>ADMIN DASHBOARD</h3>
      <ul className="nav navbar-nav">
        <li className="active">
          <button
            onClick={handleLogout}
            type="button"
            style={{ color: 'white' }}
            className={`btn btn-outline-default ${isButtonClicked ? 'btn-hover-color' : ''
              }`}
          >
            LOGOUT
          </button>
          <button
            onClick={handleBackToHome}
            type="button"
            style={{ color: 'white' }}
            className={`btn btn-outline-default ${isButtonClicked ? 'btn-hover-color' : ''
              }`}
          >
            BACK TO HOME
          </button>
        </li>
      </ul>
    </header>
  );
};

export default Header;

import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import SideNav from './Screens/SideNav';
import Login from './Screens/Login';
import EmployeePage from './Components/NewEmployee';
import Enrollment from './Screens/Enrollment';
import '@fortawesome/fontawesome-free/css/all.min.css';
import UserLogin from './Screens/UserLogin';
import ChooseTemplateScreen from './Screens/ChooseTemplateScreen';
import { UserContextProvider } from './Context/UserContext';
import "./App.css";
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserLogin, setIsUserLogin] = useState(false);
  const [verificationSuccess] = useState({ username: '' });

  const handleVerificationSuccess = (userData) => {
    // Update state or perform actions upon verification success
    setIsLoggedIn(true);
    // ...other actions
  };

  const handleVerificationFailure = () => {
    // Handle verification failure
    console.log('Verification failed');
  };

  return (
    <UserContextProvider>
      <Routes>
        <Route path="/admin" element={<Login setIsLoggedIn={setIsLoggedIn} onVerificationSuccess={handleVerificationSuccess} />} />

        {isLoggedIn ? (
          <Route
            path="/*"
            element={
              <React.Fragment>
                <SideNav />
              </React.Fragment>
            }
          />
        ) : null}
        <Route path="/:id/:username" element={<EmployeePage />} />
     
        <Route path="/" element={<Enrollment />} />

        <Route path="/userlogin" element={<UserLogin setIsUserLogin={setIsUserLogin} />} />
        {isUserLogin ? (
          <Route path="/ChooseTemplateScreen" element={<ChooseTemplateScreen />} />
        ) : null}

      </Routes>
    </UserContextProvider>
  );
};

export default App;
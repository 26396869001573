import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import axios from 'axios';

const Login = ({setIsLoggedIn}) => {    
  const [adminName, setAdminName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleAdminNameChange = (event) => {
    setAdminName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      console.log('adminName:', adminName);
      console.log('password:', password);

      const formData = new FormData();
      formData.append('adminName', adminName);
      formData.append('password', password);

      const response = await axios.post(
        'https://backend.aptkard.in/aptkard/adminlogin',
        formData
      );

      if (response.status === 200) {
        setIsLoggedIn(true)
        navigate('/home');
        console.log('Logged in successfully');
      } else {
        setError('Invalid credentials. Please try again.');
      }
    } catch (err) {
      setError('An error occurred while logging in. Please try again later.');
      console.error(err);
    }
  };

  return (
    <div className="login-container">
      <div className="card">
        <div className='login-form'>
          <img
            src="\assests\AptCard.jpg"
            alt="APT Card"
            style={{
              width: '320px',
              borderRadius: '50px',
              paddingBottom: '5px',
            }}
          />
        </div>

        <h1 className="login-heading ">Login Page</h1>
        <form className="login-form m-4" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="login-label">Username:</label>
            <input
              type="text"
              className="login-input"
              placeholder="Enter username"
              value={adminName}
              onChange={handleAdminNameChange}
            />
          </div>

          <div className="form-group">
            <label className="login-label">Password:</label>
            <input
              type="password"
              className="login-input"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="current-password"
            />
          </div>

          {error && <p className="login-error">{error}</p>}

          <div className="form-group">
            <button type="submit" className="login-button">
              Login
            </button>
          </div>
        </form>

        <h6  className=" text-center">
          Powered by <em className="gradient-text bold text-center">APT IT SOLUTIONS</em>
        </h6>
      </div>
    </div>
  );
};

export default Login;
import React, { Component } from 'react'

export default class UserEnrollment extends Component {

    constructor(props){
        super(props)
        this.state = {
          
        }
    }



  render() {
    return (
      <div>
       
      </div>
    )
  }
}